import React, {Fragment, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {useHistory} from 'react-router-dom';
import CryptoJS from 'crypto-js';

import {Alert, Button, Col, Collapse, Container, Form, Row, Spinner} from "react-bootstrap";
import {useLazyQuery} from "@apollo/client";
import {CONFIGURATION_OPTIONAL_QUERY, ESTIMATE_QUERY, FLOWS_QUERY} from "../apollo/apollo-queries";
import {AlertIcon, DashIcon, PlusIcon} from "@primer/octicons-react";
import {
    beautifyNumber,
    calculate_absorbed_power, calculate_compressed_air,
    calculate_installed_power,
    calculate_makeup_water
} from "../components/common/utils";
import MultipleDownloadModal from "../components/PDF/MultipleDownloadModalComponent";
import configJson from "../auth_config.json";

export const ConfiguratorContainer = () => {
    const history = useHistory();
    const {user} = useAuth0();

    const [getFlows, {
        loading: flowsLoading, data: flowsData, error: flowsError
    }] = useLazyQuery(FLOWS_QUERY, {
        errorPolicy: "all",
    });

    const [getConfOptData, {
        loading: confOptLoading, data: confOptData, error: confOptError
    }] = useLazyQuery(CONFIGURATION_OPTIONAL_QUERY, {
        errorPolicy: "all",
        onCompleted: data => {
            setSelectedConfiguration(data.configuration);
            setAvailableOptionals(data.optionals.map(opt => {
                return {...opt, selected: false}
            }))
        }
    });

    const [getEstimate, {
        loading: estimateLoading, error: estimateError, data: estimateData, refetch: refetchEstimate
    }] = useLazyQuery(ESTIMATE_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onCompleted: () => {
            window.scrollTo(0, document.body.scrollHeight);
        }
    });

    const [orderRef, setOrderRef] = useState("");
    const [selectedIdro, setSelectedIdro] = useState("");
    const [selectedFlow, setSelectedFlow] = useState("");
    const [availableOptionals, setAvailableOptionals] = useState([])
    const [selectedConfiguration, setSelectedConfiguration] = useState([])

    const [confModified, setConfModified] = useState(false);
    const [optionalsModified, setOptionalsModified] = useState(false);
    const [showEstimate, setShowEstimate] = useState(false);
    const [showMachines, setShowMachines] = useState(false);
    const [showOptionals, setShowOptionals] = useState(false);

    const [pdfData, setPdfData] = useState({});
    useEffect(() => {
        setShowMachines(false)
        setShowEstimate(false)
        setTimeout(() => {
            setSelectedFlow("");
            getFlows({variables: {model: selectedIdro}});
        }, 500)
    }, [getFlows, selectedIdro]);

    useEffect(() => {
        setShowMachines(selectedFlow !== "");
    }, [selectedFlow]);

    useEffect(() => {
        setShowOptionals(flowsData !== undefined && availableOptionals.length > 0 && !confOptError)
    }, [flowsData, availableOptionals.length, confOptError])

    useEffect(() => {
        setShowMachines(selectedConfiguration.length !== 0)
    }, [selectedConfiguration.length])

    useEffect(() => {
        if (showEstimate) {
            const s = {
                flow: parseInt(selectedFlow),
                plant: selectedIdro,
                optionals: {
                    "BWR": false,
                    "ZD": false,
                    "UVC": false,
                    "OZONE": false
                }
            }

            availableOptionals.forEach((opt) => {
                if (opt.selected) {
                    if (opt.name === "BWR")
                        s.optionals["BWR"] = true
                    if (opt.name === "ZD")
                        s.optionals["ZD"] = true
                    if (opt.name === "UVC")
                        s.optionals["UVC"] = true
                    if (opt.name === "OZONE")
                        s.optionals["OZONE"] = true
                }
            })


            s['total_installed_power'] = calculate_installed_power(s);
            s['total_absorbed_power'] = calculate_absorbed_power(s['total_installed_power']);
            s['makeup_water'] = calculate_makeup_water(s);
            s['compressed_air'] = calculate_compressed_air(s);

            s['optionals'] = availableOptionals.filter(({selected}) => selected)
            s['configuration'] = selectedConfiguration
            s['orderRef'] = orderRef
            s['estimate'] = estimateData && estimateData.estimate

            console.log(s);
            setPdfData(s);
        }
    }, [availableOptionals, estimateData, orderRef, selectedConfiguration, selectedFlow, selectedIdro, showEstimate])

    const saveObjectToLocalStorage = (obj) => {
        // Encrypt the object before storing it
        const encryptedObject = CryptoJS.AES.encrypt(
            JSON.stringify(obj),
            user.email
        ).toString();

        localStorage.setItem('estimate', encryptedObject);
    };

    return (<Container className="mb-5 mt-5">
        <Container className={"text-center d-flex flex-column"}>
            <Form className={"mt-1"}>
                <Row>
                    <Col md={3} className={"text-start"}>
                        <h3 className={"color-red"}>Reference</h3>
                    </Col>
                    <Col md={6}>
                        <Form.Control type={"text"} placeholder={"Offer number"} value={orderRef}
                                      onChange={event => setOrderRef(event.target.value)}/>
                    </Col>
                    <Col md={3}/>
                </Row>
                <Row className={"mt-5"}>
                    <Col md={3} className={"text-start"}>
                        <h3 className={"color-red"}> Base configuration</h3>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Row className={"text-start"}><Form.Label
                                className={"color-red"}>Model</Form.Label></Row>
                            <Form.Control
                                as={"select"}
                                type={"text"}
                                value={selectedIdro}
                                onChange={event => setSelectedIdro(event.target.value)}
                                disabled={showEstimate}
                            >
                                <option value={""}>Choose the model</option>
                                <option value={"IDRO 1"}>IDRO 1</option>
                                <option value={"IDRO 2"}>IDRO 2</option>
                                <option value={"IDRO 3"}>IDRO 3</option>
                                <option value={"IDRO 4"}>IDRO 4</option>
                                <option value={"IDRO 5"}>IDRO 5</option>
                                <option value={"IDRO 6"}>IDRO 6</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className={"mt-3"}>
                            <Row className={"text-start"}>
                                <Form.Label className={"color-red"}>
                                    Flow {flowsLoading && <Spinner animation="grow" role="status" size={"sm"}
                                                                   className={"me-1 color-red"}/>}
                                </Form.Label>
                            </Row>
                            <Form.Control
                                as={"select"}
                                type={"text"}
                                value={selectedFlow}
                                onChange={event => {
                                    setSelectedFlow(event.target.value);
                                    showMachines && getConfOptData({
                                        variables: {
                                            model: selectedIdro, flow: parseInt(event.target.value)
                                        }
                                    });
                                }}
                                disabled={showEstimate || !flowsData || flowsData.flows.length === 0 || flowsError}
                            >
                                <option value={""}>Choose the flow</option>
                                {!flowsLoading && flowsData && flowsData.flows.map(flow => <option
                                    key={flow}
                                    value={flow}> {flow}</option>)}
                            </Form.Control>
                            {flowsError &&
                                <span className={"color-red"}> Error! Select on of the given models and retry.</span>}
                        </Form.Group>
                        {confOptError &&
                            <span className={"color-red"}> Error! Select on of the given models/flows and retry.</span>}
                        <Collapse
                            in={showMachines}
                            onEntering={() => {
                                getConfOptData({
                                    variables: {
                                        model: selectedIdro, flow: parseInt(selectedFlow)
                                    }
                                });
                            }}
                            onExited={() => {
                                setSelectedConfiguration([]);
                                setAvailableOptionals([]);
                            }}
                        >
                            <Form.Group>
                                {selectedConfiguration.length !== 0 && <Row className={"text-start mt-3"}>
                                    <Form.Label className={"color-red"}>
                                        Machines {confOptLoading &&
                                        <Spinner animation="grow" role="status" size={"sm"}
                                                 className={"me-1 color-red"}/>}
                                    </Form.Label>
                                </Row>}
                                {selectedConfiguration.length !== 0 && <Row className={"text-start mt-3"}>
                                    <Col/>
                                    <Col className={"text-center"}><Form.Label className={"color-red"}>
                                        Name
                                    </Form.Label></Col>
                                    <Col className={"text-end"}><Form.Label className={"color-red"}>
                                        Quantity
                                    </Form.Label></Col>
                                </Row>}
                                {!confOptLoading && selectedConfiguration.map((conf, index) => <Row
                                    key={index}>
                                    <Col className={"d-flex flex-row"}>
                                        {conf.modifiable &&
                                            <Fragment>
                                                <Button
                                                    variant={"link"}
                                                    size={"sm"}
                                                    onClick={() => {
                                                        const originalConfItem = confOptData.configuration.find(({model}) => model === conf.model);
                                                        originalConfItem.quantity === conf.quantity + 1 ? setConfModified(false) : setConfModified(true);
                                                        setSelectedConfiguration(selectedConfiguration.map((c, idx) => idx === index ? {
                                                            ...conf, quantity: conf.quantity + 1
                                                        } : c))
                                                    }}
                                                    disabled={showEstimate}
                                                >
                                                    <PlusIcon className={"color-red"}/>
                                                </Button>
                                                <Button
                                                    variant={"link"} size={"sm"}
                                                    disabled={showEstimate || conf.quantity === 0}
                                                    onClick={() => {
                                                        const originalConfItem = confOptData.configuration.find(({model}) => model === conf.model);
                                                        originalConfItem.quantity === conf.quantity - 1 ? setConfModified(false) : setConfModified(true);
                                                        setSelectedConfiguration(selectedConfiguration.map((c, idx) => idx === index ? {
                                                            ...conf, quantity: conf.quantity - 1
                                                        } : c))
                                                    }}
                                                >
                                                    <DashIcon className={"color-red"}/>
                                                </Button>
                                            </Fragment>
                                        }
                                    </Col>
                                    <Col className={"color-black"}>
                                        {conf.name.toUpperCase()} ({conf.model})
                                    </Col>
                                    <Col className={"color-black text-end"}>
                                        {conf.quantity}
                                    </Col>
                                </Row>)}
                                {confModified && <Row className={"text-center"}>
                                    <Alert variant={"warning"} className={"mt-2"}>
                                            <span>
                                                <AlertIcon/>
                                                {` Warning: this is not the stadard configuration designed by Idrosistem. Filtration process cannot be guaranteed. Please contact Idrosistem for confirmation! `}
                                                <Button variant={"link"}
                                                        onClick={() => {
                                                            setSelectedConfiguration(confOptData.configuration);
                                                            setConfModified(false);
                                                        }}
                                                >
                                                Click here to reset.
                                                </Button>
                                            </span>
                                    </Alert>
                                </Row>}
                            </Form.Group>
                        </Collapse>
                    </Col>
                    <Col md={3}/>
                </Row>
                <Collapse in={showOptionals}>
                    <Row className={"mt-5"}>
                        <Col md={3} className={"text-start"}>
                            {flowsData && availableOptionals.length > 0 && <h3 className={"color-red"}> Optionals</h3>}
                        </Col>
                        <Col md={6}>
                            {flowsLoading && <Row className={"text-center"}>
                                <Alert variant={"link"} className={"mt-2"}>
                                    <Spinner animation="grow" role="status" size={"sm"}
                                             className={"me-1"}/>
                                    <span>{`Loading...`}</span>
                                </Alert>
                            </Row>}
                            {flowsData && availableOptionals.length > 0 && <Row>
                                <Col className={"text-start"}>
                                    <Form.Label className={"color-red"}>Optional (mu)</Form.Label>
                                </Col>
                                <Col className={"text-center"}>
                                    <Form.Label className={"color-red"}>Description</Form.Label>
                                </Col>
                                <Col className={"text-end"}>
                                    <Form.Label className={"color-red"}>Price</Form.Label>
                                </Col>
                            </Row>}
                            {flowsData && availableOptionals
                                .map((optional, index) => {
                                    let label = `${optional.name}`
                                    if (optional.um) label += ` (${optional.um})`;

                                    if (optional.mutual_exclusion) label += `*`;


                                    return <Row key={index}>
                                        <Col className={'text-start'}>
                                            <Form.Check
                                                type={"checkbox"}
                                                className={"color-black"}
                                                key={index}
                                                label={label}
                                                onChange={event => setAvailableOptionals(availableOptionals.map(innerOptional => innerOptional.name === optional.name ? {
                                                    ...innerOptional, selected: event.target.checked
                                                } : innerOptional))}
                                                checked={optional.selected}
                                                disabled={showEstimate}
                                            />
                                        </Col>
                                        <Col className={"text-center"}>
                                            {optional.description ? optional.description : "-"}
                                        </Col>
                                        <Col className={"text-end"}>
                                            {optional.quantity !== null ? beautifyNumber(parseInt(optional.quantity * optional.price)) : beautifyNumber(optional.price)} €
                                        </Col>
                                    </Row>
                                })}
                            {flowsData && availableOptionals.length > 0 && <Row> * Mutual exclusive fields</Row>}
                            {availableOptionals.filter(({editable, selected}) => editable && selected).length > 0 &&
                                <Fragment>
                                    <Row className={"mt-3 mb-2"}>
                                        <Col className={"text-start"}>
                                            <Form.Label className={"color-red"}>Edit quantity for variables
                                                optional</Form.Label>
                                        </Col>
                                    </Row>
                                    {availableOptionals
                                        .filter(({editable, selected}) => editable && selected)
                                        .map((optional, index) => <Row key={index}>
                                            <Col className={"text-start"}>
                                                <Form.Label> {optional.name}</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type={"text"}
                                                    value={optional.quantity}
                                                    onChange={event => {
                                                        const originalOptional = confOptData.optionals.find(({name}) => name === optional.name);
                                                        parseInt(originalOptional.quantity) === parseInt(event.target.value) ? setOptionalsModified(false) : setOptionalsModified(true);
                                                        setAvailableOptionals(availableOptionals.map(innerOptional => innerOptional.name === optional.name ? {
                                                            ...innerOptional, quantity: event.target.value
                                                        } : innerOptional));
                                                    }}
                                                    disabled={showEstimate}
                                                />
                                            </Col>
                                        </Row>)}
                                    {<Row className={"mt-3"}>
                                        {optionalsModified ?
                                            <Alert variant={"warning"} className={"mt-2"}>
                                            <span>
                                                <AlertIcon/>
                                                {` Warning! You edited the standard configuration for the selected optional(s)! `}
                                                <Button variant={"link"} onClick={() => {
                                                    setAvailableOptionals(confOptData.optionals.map((opt, index) => {
                                                        return {
                                                            ...opt, selected: availableOptionals[index].selected
                                                        }
                                                    }));
                                                    setOptionalsModified(false);
                                                }}
                                                >
                                                Click here to reset.
                                                </Button>
                                            </span>
                                            </Alert> :
                                            <p> Note: these are manufacturer's suggested values for the given
                                                optionals. </p>

                                        }
                                    </Row>}
                                </Fragment>}
                        </Col>
                        <Col md={3}/>
                    </Row>
                </Collapse>
                {!showEstimate && flowsData && availableOptionals.length > 0 && <Row>
                    <Col md={3}/>
                    <Col md={6} className={"text-end"}>
                        <hr className={"color-red"}/>
                        <Button
                            variant={"success"}
                            disabled={
                                selectedIdro === "" || selectedFlow === ""
                            }
                            onClick={() => {
                                setShowEstimate(true);
                            }}>{estimateLoading ? <Spinner animation="grow" role="status" size={"sm"}
                                                           className={"me-1"}/> : "Get estimate"}</Button>
                    </Col>
                    <Col md={3}/>
                </Row>}
                <Collapse
                    in={showEstimate}
                    onEntering={() => {
                        const vars = {
                            "conf": selectedConfiguration.map(({name, quantity, model}) => {
                                return {name: name, quantity: quantity, model: model};
                            }),
                            "model": selectedIdro,
                            "flow": parseInt(selectedFlow),
                            "optionals": availableOptionals.filter(({selected}) => selected).map(optional => {
                                const result = {name: optional.name};
                                if (optional.quantity) result['quantity'] = parseInt(optional.quantity)

                                return result;
                            })
                        };
                        getEstimate({
                            variables: vars
                        })
                    }}
                    onExited={() => {
                        setSelectedConfiguration([]);
                    }}
                >
                    {((!estimateLoading && !estimateError && estimateData)) ?
                        <Container>
                            <Row>
                                <Col md={3}/>
                                <Col md={6}>
                                    <hr className={"color-red"}/>
                                    <Row>
                                        <Col><h5 className={"text-start color-red"}>Base total:</h5></Col>
                                        <Col className={"text-end color-red"}>{
                                            <h5>{beautifyNumber(estimateData.estimate.baseTotal)} € </h5>}</Col>
                                    </Row>
                                    <Row>
                                        <Col><h5 className={"text-start color-red"}>Optionals total:</h5></Col>
                                        <Col className={"text-end color-red"}>{
                                            <h5>{beautifyNumber(estimateData.estimate.optionalsTotal)} € </h5>}</Col>
                                    </Row>
                                    <hr className={"color-red"}/>
                                    <Row>
                                        <Col><h4 className={"text-start color-red"}>Estimate total:</h4></Col>
                                        <Col className={"text-end color-red"}>{
                                            <h5>{beautifyNumber(estimateData.estimate.total)} € </h5>}</Col>
                                    </Row>
                                </Col>
                                <Col md={3}/>
                            </Row>
                            <Row>
                                <Col md={3}/>
                                <Col md={6} className={"text-end"}>
                                    <hr className={"color-red"}/>
                                    <Row>
                                        <Col>
                                            <div className={'justify-content-start'}><MultipleDownloadModal
                                                className={"ms-2"}
                                                data={pdfData}
                                                defaultName={orderRef}
                                                docTypes={[
                                                    {code: "basic_report", name: "Only Estimate"},
                                                    {code: "utilities", name: "Estimate + utilities"},
                                                    {code: "diagrams", name: "Estimate + diagrams"},
                                                    {code: "full", name: "Full estimate"}
                                                ]}
                                                buttonText={"Download PDF"}
                                            /></div>
                                        </Col>
                                        <Col>
                                            <div className={'justify-content-end'}><Button
                                                variant={"outline-danger"}
                                                onClick={() => {
                                                    setShowEstimate(false);
                                                    setShowMachines(false);
                                                    setShowOptionals(false);
                                                    setSelectedConfiguration([]);
                                                    setSelectedIdro("");
                                                    setOptionalsModified(false);
                                                    setConfModified(false);
                                                }}
                                                className={'me-2'}>Reset</Button>
                                                {user && user[configJson.roles].includes("operator") &&
                                                    <Button
                                                        variant={"outline-success"}
                                                        onClick={() => {
                                                            saveObjectToLocalStorage({
                                                                "model": selectedIdro,
                                                                "flow": selectedFlow,
                                                                selectedConfiguration,
                                                                optionals: availableOptionals.filter(({selected}) => selected).map(optional => {
                                                                    const result = {name: optional.name};
                                                                    if (optional.quantity) result['quantity'] = parseInt(optional.quantity)
                                                                    if (optional.description) result['description'] = optional.description

                                                                    return result;
                                                                }),
                                                                utilities: {
                                                                    totalInstalledPower: pdfData['total_installed_power'],
                                                                    totalAbsorbedPower: pdfData['total_absorbed_power'],
                                                                    makeupWater: pdfData['makeup_water'],
                                                                    compressedAir: pdfData['compressed_air']
                                                                }
                                                            });
                                                            history.push('/offer');
                                                        }}>Make offer</Button>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}/>
                            </Row>
                        </Container>
                        :
                        estimateError ?
                            <span className={"color-red"}> There was an error with the selected configuration/optionals. Check the parameters and retry.</span> :
                            <Row/>
                    }
                </Collapse>
                {showEstimate && estimateError && <Row>
                    <Col md={3}/>
                    <Col md={6} className={"text-end"}>
                        <hr className={"color-red"}/>
                        <Button
                            variant={"success"}
                            className={"me-3"}
                            disabled={
                                availableOptionals.filter(({selected}) => selected).length === 0
                            }
                            onClick={() => {
                                const vars = {
                                    "conf": selectedConfiguration.map(({name, quantity, model}) => {
                                        return {name: name, quantity: quantity, model: model};
                                    }),
                                    "model": selectedIdro,
                                    "flow": parseInt(selectedFlow),
                                    "optionals": availableOptionals.filter(({selected}) => selected).map(optional => {
                                        const result = {name: optional.name};
                                        if (optional.quantity) result['quantity'] = optional.quantity

                                        return result;
                                    })
                                };
                                refetchEstimate(vars);
                            }}>{estimateLoading ? <Spinner animation="grow" role="status" size={"sm"}
                                                           className={"me-1"}/> : "Try again"}</Button>
                        <Button
                            variant={"outline-danger"}
                            onClick={() => {
                                setShowEstimate(false);
                                setShowMachines(false);
                                setShowOptionals(false);
                                setSelectedConfiguration([]);
                                setSelectedIdro("");
                                setOptionalsModified(false);
                                setConfModified(false);
                            }}>Reset</Button>
                    </Col>
                    <Col md={3}/>
                </Row>}
            </Form>
        </Container>
    </Container>);
};

export default withAuthenticationRequired(ConfiguratorContainer, {
    onRedirecting: () => <Loading/>,
});
