import React from "react";

const Footer = () => (
    <footer className="bg-red text-light p-4">
        <div className="container text-center">
            <div className="row">
                <div className="col-12">
                    <h6>DISCLAIMER</h6>
                    <p className="text-sm-center text-md-start size-xsm size-md-sm">
                        The information contained and generated by this application is confidential, privileged and destined for
                        the sole use of the company Trützschler Nonwovens & Man-Made Fibers GmbH, and may not be used,
                        published, reproduced or redistributed without the prior written consent of Idrosistem energy srl. Any
                        violation of these conditions is punishable by law and violators may be prosecuted accordingly.
                    </p>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
