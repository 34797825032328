import React, {Fragment, useCallback, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/Row";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {Button, Col, Collapse, Container, Form, InputGroup, Nav, Spinner, Tab, ToastContainer} from "react-bootstrap";
import {Notification} from "../components/common/Notification";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    ALL_DELTAS,
    BASE_AUX_VARIATION,
    BASE_MACHINE_VARIATION,
    COST_CENTERS_QUERY,
    DELTA_QUERY,
    FLOWS_QUERY,
    GENERAL_VARIATION,
    GLOBAL_COST_VARIATION,
    GLOBAL_OPTIONAL_VARIATION,
    GLOBALS_QUERY, HISTORY_QUERY, IDRO_DELTA_VARIATION,
    MACHIINES_QUERY, OPTIONAL_DELTA_VARIATION,
    OPTIONAL_VARIATION,
    OTHER_COSTS_QUERY,
    RAW_OPTIONALS
} from "../apollo/apollo-queries";
import Table from "react-bootstrap/Table";


export const BackofficeContainer = () => {
    const {
        loading: globalsLoading,
        data: globals,
        refetch: refetchGlobals
    } = useQuery(GLOBALS_QUERY, {
        errorPolicy: "all",
        onCompleted: data => {
            setGlobalsData(data.globalCosts)
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error during data fetch!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const {
        loading: machinesLoading,
        data: machines,
        refetch: refetchMachines
    } = useQuery(MACHIINES_QUERY, {
        errorPolicy: "all",
        onCompleted: data => setMachinesData(data.machinesCosts),
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error during data fetch!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const {
        loading: deltasLoading, data: originalDeltas, refetch: refetchDeltas
    } = useQuery(ALL_DELTAS, {
        errorPolicy: "all",
        onCompleted: data => setAllDeltas(data.deltas),
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error during data fetch!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const {loading: costCentersLoading, data: costCenters} = useQuery(COST_CENTERS_QUERY, {
        errorPolicy: "all",
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error during data fetch!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [getFlows, {
        loading: flowsLoading, data: flowsData
    }] = useLazyQuery(FLOWS_QUERY, {
        errorPolicy: "all",
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [getOtherCosts, {
        loading: otherCostsLoading, data: originalOC, refetch: refetchOC
    }] = useLazyQuery(OTHER_COSTS_QUERY, {
        errorPolicy: "all",
        onCompleted: data => {
            setOtherCostsData(data.otherCosts);
            setShowOC(true);
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [getOptionals, {
        loading: optionalsLoading, data: originalOptionals, refetch: refetchOptionals
    }] = useLazyQuery(RAW_OPTIONALS, {
        errorPolicy: "all",
        onCompleted: data => {
            setOptionalsData(data.rawOptionals);
            setShowOptionals(true);
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [getDelta, {
        data: originalDelta, refetch: refetchDelta
    }] = useLazyQuery(DELTA_QUERY, {
        errorPolicy: "all",
        onCompleted: data => setDelta(data.delta.toString()),
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [getHistory, {
        loading: historyLoading
    }] = useLazyQuery(HISTORY_QUERY, {
        errorPolicy: "all",
        onCompleted: data => setHistoryData(data.historyOfChanges),
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [editBaseAuxCost, {
        loading: baseAuxLoading
    }] = useMutation(BASE_AUX_VARIATION, {
        errorPolicy: "all",
        onCompleted: async () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "success"
            }])
            await refetchDelta();
            await refetchOC();
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [editGlobalOptional, {
        loading: globalOptionalLoading
    }] = useMutation(GLOBAL_OPTIONAL_VARIATION, {
        errorPolicy: "all",
        onCompleted: async () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "danger"
            }])
            refetchGlobals();
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [editOptional, {
        loading: editOptionalLoading
    }] = useMutation(OPTIONAL_VARIATION, {
        errorPolicy: "all",
        onCompleted: async () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "success"
            }])
            await refetchOptionals();
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [editBaseMachine, {
        loading: editBaseMachineLoading
    }] = useMutation(BASE_MACHINE_VARIATION, {
        errorPolicy: "all",
        onCompleted: async () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "success"
            }])
            refetchMachines()
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [generalEdit, {
        loading: generalEditLoading
    }] = useMutation(GENERAL_VARIATION, {
        errorPolicy: "all",
        onCompleted: async () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "success"
            }])
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [globalCostEdit, {
        loading: globalCostEditLoading
    }] = useMutation(GLOBAL_COST_VARIATION, {
        errorPolicy: "all",
        onCompleted: async () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "success"
            }])
            await refetchGlobals()
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [editDelta, {
        loading: editDeltaLoading
    }] = useMutation(IDRO_DELTA_VARIATION, {
        errorPolicy: "all",
        onCompleted: async () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "success"
            }])
            await refetchGlobals()
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [editOptionalDelta, {
        loading: editOptionalDeltaLoading
    }] = useMutation(OPTIONAL_DELTA_VARIATION, {
        errorPolicy: "all",
        onCompleted: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Modifca avvenuta con successo",
                body: "",
                variant: "success"
            }])
        },
        onError: () => {
            setNotifications((notifications) => [...notifications, {
                title: "Error, check data and retry!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        }
    });

    const [selectedIdro, setSelectedIdro] = useState("");
    const [selectedFlow, setSelectedFlow] = useState("");
    const [selectedMachine, setSelectedMachine] = useState("");
    const [selectedCostCenter, setSelectedCostCenter] = useState("");
    const [selectedVariationType, setSelectedVariationType] = useState("percentage");
    const [selectedVariationAmount, setSelectedVariationAmount] = useState("");

    const [delta, setDelta] = useState("");
    const [otherCostsData, setOtherCostsData] = useState([]);
    const [globalsData, setGlobalsData] = useState([]);
    const [machinesData, setMachinesData] = useState([]);
    const [optionalsData, setOptionalsData] = useState([]);
    const [allDeltas, setAllDeltas] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const [showOC, setShowOC] = useState(false);
    const [showMachineModels, setShowMachineModels] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [showOptionals, setShowOptionals] = useState(false);

    const applyMutation = useCallback((item) => {
        let vars;
        switch (item.type) {
            case "base_aux":
                vars = {
                    "name": item.name, "model": item.model, "value": parseInt(item.cost), "flow": parseInt(selectedFlow)
                };
                editBaseAuxCost({
                    variables: vars
                });
                break;
            case "base_machine":
                vars = {
                    "name": item.name,
                    "machineModel": item.model,
                    "value": parseInt(item.cost)
                }
                editBaseMachine({
                    variables: vars
                });
                break;
            case "global":
                vars = {
                    "name": item.name, "amount": parseFloat(item.value), "value_type": item.value_type
                }

                globalCostEdit({
                    variables: vars
                });
                break;
            case "global_optional":
                vars = {
                    "name": item.name,
                    "delta": parseFloat(item.delta),
                    "value_type": item.value_type,
                    "value": parseInt(item.value)
                }
                editGlobalOptional({
                    variables: vars
                });
                break;
            case "optional":
                vars = {
                    "name": item.name, "model": selectedIdro, "flow": parseInt(selectedFlow)
                }

                if (item.cost) vars["value"] = parseInt(item.cost)
                if (item.delta) vars["delta"] = parseFloat(item.delta)

                editOptional({
                    variables: vars
                });
                break;
            default:
                break;
        }
    }, [editBaseAuxCost, editBaseMachine, editGlobalOptional, editOptional, globalCostEdit, selectedFlow, selectedIdro])

    useEffect(() => {
        selectedIdro !== "" ? getDelta({variables: {model: selectedIdro}}) : setDelta("");
        setTimeout(() => {
            setSelectedFlow("");
            getFlows({variables: {model: selectedIdro}});
        }, 500)
    }, [getDelta, getFlows, selectedIdro]);

    useEffect(() => {
        setShowOC(selectedFlow !== "");
        setShowOptionals(selectedFlow !== "");
    }, [selectedFlow]);

    useEffect(() => {
        if (selectedFlow !== "")
            getOtherCosts({
                variables: {
                    model: selectedIdro,
                    flow: parseInt(selectedFlow)
                }
            })
        else
            setShowOC(false);

        if (selectedFlow !== "")
            getOptionals({
                variables: {
                    model: selectedIdro,
                    flow: parseInt(selectedFlow)
                }
            })
        else
            setShowOptionals(false);

    }, [getOptionals, getOtherCosts, selectedFlow, selectedIdro]);

    useEffect(() => setShowMachineModels(selectedMachine !== "" && machines !== undefined), [selectedMachine, machines])

    useEffect(() => setShowHistory(selectedCostCenter !== ""), [selectedCostCenter])

    useEffect(() => {
        selectedCostCenter !== "" && console.log({variables: {name: costCenters.costCenters[parseInt(selectedCostCenter)].name}})

        selectedCostCenter !== "" && getHistory({variables: {name: costCenters.costCenters[parseInt(selectedCostCenter)].name}})
    }, [costCenters, getHistory, selectedCostCenter])

    return (
        <Container className="mb-5 mt-3">
            <Container className="p-5 mb-4 bg-light rounded-3">
                <h1 className="color-red">Backoffice</h1>
            </Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="models">
                <ToastContainer position="top-end" className="p-3" containerPosition={"position-fixed"}>
                    {notifications.map((n, index) => <Notification key={index} title={n.title} variant={n.variant}
                                                                   body={n.body}/>)}
                </ToastContainer>
                <Row style={{marginTop: "2em"}}>
                    <Col md={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="models">Configurations</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="optionals">Optionals</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="globalCosts">Global Costs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="machines">Machine Costs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="variations">Cost variations</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="deltas">Optional deltas</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="history">History</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col md={{offset: 1, span: 5}}>
                        <Tab.Content>
                            <Tab.Pane eventKey={"models"}>
                                <Form noValidate validated={false}>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>Model</Form.Label>
                                            </Row>
                                            <Form.Control
                                                as={"select"}
                                                type={"text"}
                                                value={selectedIdro}
                                                onChange={event => setSelectedIdro(event.target.value)}
                                            >
                                                <option value={""}>Choose the model</option>
                                                <option value={"IDRO 1"}>IDRO 1</option>
                                                <option value={"IDRO 2"}>IDRO 2</option>
                                                <option value={"IDRO 3"}>IDRO 3</option>
                                                <option value={"IDRO 4"}>IDRO 4</option>
                                                <option value={"IDRO 5"}>IDRO 5</option>
                                                <option value={"IDRO 6"}>IDRO 6</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>
                                                    Flow {flowsLoading &&
                                                    <Spinner animation="grow" role="status" size={"sm"}
                                                             className={"me-1 color-red"}/>}
                                                </Form.Label>
                                            </Row>
                                            <Form.Control
                                                as={"select"}
                                                type={"text"}
                                                value={selectedFlow}
                                                onChange={event => setSelectedFlow(event.target.value)}
                                            >
                                                <option value={""}>Choose the flow</option>
                                                {!flowsLoading && flowsData && flowsData.flows.map(flow => <option
                                                    key={flow}
                                                    value={flow}> {flow}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Col>
                                            <Form.Group>
                                                <Row className={"text-start"}>
                                                    <Form.Label className={"color-red"}>Delta</Form.Label>
                                                </Row>
                                                <Form.Control
                                                    type={"text"} placeholder={"Delta (%)"}
                                                    value={delta}
                                                    onChange={event => setDelta(event.target.value)}
                                                    isInvalid={isNaN(delta) || delta <= 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Delta needs to be a positive number.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {
                                        otherCostsLoading && <Row className={"d-flex align-content-center mt-3"}>
                                            <Col>
                                                <Spinner animation={"border"} className={"me-1 color-red"}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className={"mt-3"}>
                                        <Collapse in={showOC} onExit={() => {
                                            setOtherCostsData([])
                                        }}>
                                            <Container>
                                                <Row className={"text-start"}>
                                                    <Form.Label className={"color-red"}>Cost Centers</Form.Label>
                                                </Row>
                                                {!otherCostsLoading && otherCostsData.map((oc, index) =>
                                                    <InputGroup className="mb-3" key={index}>
                                                        <InputGroup.Text id="basic-addon1"
                                                                         className={"w-50"}>{oc.name}</InputGroup.Text>
                                                        <Form.Control
                                                            className={"text-end"}
                                                            placeholder="Cost"
                                                            aria-label="Cost"
                                                            aria-describedby="cost"
                                                            value={oc.cost}
                                                            isInvalid={isNaN(oc.cost) || parseFloat(oc.cost) < 0}
                                                            onChange={
                                                                event => setOtherCostsData(costs => costs.map(c => c.name === oc.name ? {
                                                                    ...c,
                                                                    cost: event.target.value
                                                                } : c))
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {oc.name} needs to be a positive number.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                )}</Container>
                                        </Collapse>
                                    </Row>
                                    <hr className={"color-red"}/>
                                    <Row className={"text-end"}>
                                        <Col md={6} className={"text-md-start"}>
                                            <Button
                                                variant={"outline-danger"}
                                                className={"me-3"}
                                                onClick={() => {
                                                    setSelectedIdro("");
                                                    setDelta("")
                                                    setSelectedFlow("");
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                        <Col md={6} className={"text-md-end"}>
                                            <Button
                                                variant={"success"}
                                                disabled={
                                                    (selectedFlow === "") ||
                                                    ((delta === "" || originalDelta === undefined || parseFloat(delta) === originalDelta.delta) &&
                                                        (otherCostsData.length > 0 && originalOC !== undefined && originalOC.otherCosts.reduce((res, {cost}, idx) => res && cost === parseFloat(otherCostsData[idx].cost), true)))
                                                }
                                                onClick={() => {
                                                    if (parseFloat(delta) !== originalDelta.delta)
                                                        editDelta({
                                                            variables: {
                                                                "model": selectedIdro,
                                                                "value": parseFloat(delta)
                                                            }
                                                        }).then(() => refetchDelta());
                                                    otherCostsData.filter((item, idx) => {
                                                        return item.cost !== originalOC.otherCosts[idx].cost
                                                    }).forEach(item => applyMutation(item));
                                                }}
                                            >
                                                Save changes {(editDeltaLoading || baseAuxLoading) &&
                                                <Spinner animation="grow" role="status" size={"sm"}
                                                         className={"ms-1"}/>}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"optionals"}>
                                <Form>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>Model</Form.Label>
                                            </Row>
                                            <Form.Control
                                                as={"select"}
                                                type={"text"}
                                                value={selectedIdro}
                                                onChange={event => setSelectedIdro(event.target.value)}
                                            >
                                                <option value={""}>Choose the model</option>
                                                <option value={"IDRO 1"}>IDRO 1</option>
                                                <option value={"IDRO 2"}>IDRO 2</option>
                                                <option value={"IDRO 3"}>IDRO 3</option>
                                                <option value={"IDRO 4"}>IDRO 4</option>
                                                <option value={"IDRO 5"}>IDRO 5</option>
                                                <option value={"IDRO 6"}>IDRO 6</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>
                                                    Flow {flowsLoading &&
                                                    <Spinner animation="grow" role="status" size={"sm"}
                                                             className={"me-1 color-red"}/>}
                                                </Form.Label>
                                            </Row>
                                            <Form.Control
                                                as={"select"}
                                                type={"text"}
                                                value={selectedFlow}
                                                onChange={event => setSelectedFlow(event.target.value)}
                                            >
                                                <option value={""}>Choose the flow</option>
                                                {!flowsLoading && flowsData && flowsData.flows.map(flow => <option
                                                    key={flow}
                                                    value={flow}> {flow}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    {
                                        optionalsLoading && <Row className={"d-flex align-content-center mt-3"}>
                                            <Col>
                                                <Spinner animation={"border"} className={"me-1 color-red"}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className={"mt-3"}>
                                        <Collapse in={showOptionals} onExit={() => setOptionalsData([])}>
                                            <Container>
                                                <Row className={"text-start"}>
                                                    <Form.Label className={"color-red"}>Optionals</Form.Label>
                                                </Row>
                                                {!optionalsLoading && optionalsData.map((optional, index) =>
                                                    <InputGroup className="mb-3" key={index}>
                                                        <InputGroup.Text id="basic-addon1"
                                                                         className={"w-50"}>{optional.name}</InputGroup.Text>
                                                        <Form.Control
                                                            className={"text-end"}
                                                            placeholder="Cost"
                                                            aria-label="Cost"
                                                            aria-describedby="cost"
                                                            value={optional.cost}
                                                            isInvalid={isNaN(optional.cost) || parseFloat(optional.cost) < 0}
                                                            onChange={
                                                                event => setOptionalsData(opts =>
                                                                    opts.map(o => o.name === optional.name ? {
                                                                        ...o,
                                                                        cost: event.target.value
                                                                    } : o)
                                                                )
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {optional.name} needs to be a positive number.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                )}</Container>
                                        </Collapse>
                                    </Row>
                                    <hr className={"color-red"}/>
                                    <Row className={"text-end"}>
                                        <Col md={6} className={"text-md-start"}>
                                            <Button
                                                variant={"outline-danger"}
                                                className={"me-md-3"}
                                                onClick={() => {
                                                    setSelectedIdro("");
                                                    setSelectedFlow("");
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                        <Col md={6} className={"text-md-end"}>
                                            <Button
                                                variant={"success"}
                                                disabled={
                                                    (selectedFlow === "") ||
                                                    (
                                                        optionalsData.length > 0 &&
                                                        originalOptionals !== undefined &&
                                                        originalOptionals.rawOptionals.reduce((res, {cost}, idx) => res && cost === parseFloat(optionalsData[idx].cost), true)
                                                    )
                                                }
                                                onClick={() => {
                                                    optionalsData.filter((item, idx) => {
                                                        return parseFloat(item.price) !== originalOptionals.rawOptionals[idx].price
                                                    }).forEach(item => applyMutation(item));
                                                }}
                                            >
                                                Save changes {editOptionalLoading &&
                                                <Spinner animation="grow" role="status" size={"sm"}
                                                         className={"ms-1"}/>}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"globalCosts"}>
                                <Form>
                                    {
                                        (globalsLoading || machinesLoading) &&
                                        <Row className={"d-flex align-content-center mt-3"}>
                                            <Col>
                                                <Spinner animation={"border"} className={"me-1 color-red"}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>Global costs</Form.Label>
                                            </Row>
                                            {!globalsLoading && globalsData.map((global, index) =>
                                                <Fragment>
                                                    <InputGroup className="mb-3" key={index}>
                                                        <InputGroup.Text id="basic-addon1"
                                                                         className={"w-50"}>{global.name}</InputGroup.Text>
                                                        <Form.Control
                                                            className={"text-end"}
                                                            placeholder="Cost"
                                                            aria-label="Cost"
                                                            aria-describedby="cost"
                                                            value={global.value}
                                                            isInvalid={isNaN(global.value) || parseFloat(global.value) < 0}
                                                            onChange={
                                                                event => setGlobalsData(glbls =>
                                                                    glbls.map(o => o.name === global.name ? {
                                                                        ...o,
                                                                        value: event.target.value
                                                                    } : o)
                                                                )
                                                            }
                                                        />
                                                    </InputGroup>
                                                    <Form.Control.Feedback type="invalid">
                                                        {global.name} needs to be a positive number.
                                                    </Form.Control.Feedback>
                                                </Fragment>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <hr className={"color-red"}/>
                                    <Row className={"text-end"}>
                                        <Col md={6} className={"text-md-start"}>
                                            <Button
                                                variant={"outline-danger"}
                                                className={"me-md-3"}
                                                onClick={() => {
                                                    refetchGlobals();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                        <Col md={6} className={"text-md-end"}>
                                            <Button
                                                variant={"success"}
                                                disabled={
                                                    !globals || globalsData.length === 0 || globals.globalCosts.reduce((res, {value}, idx) => res && value === parseFloat(globalsData[idx].value), true)
                                                }
                                                onClick={() => {
                                                    globalsData.filter((item, idx) => {
                                                        return parseFloat(item.value) !== globals.globalCosts[idx].value
                                                    }).forEach(item => applyMutation(item));
                                                }}
                                            >
                                                Save changes {(globalCostEditLoading || globalOptionalLoading) &&
                                                <Spinner animation="grow" role="status" size={"sm"}
                                                         className={"ms-1"}/>}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"machines"}>
                                <Form>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>
                                                    Machines {machinesLoading &&
                                                    <Spinner animation="grow" role="status" size={"sm"}
                                                             className={"me-1 color-red"}/>}
                                                </Form.Label>
                                            </Row>
                                            <Form.Control
                                                as={"select"}
                                                type={"text"}
                                                value={selectedMachine}
                                                onChange={event => setSelectedMachine(event.target.value)}
                                            >
                                                <option value={""}>Choose the model</option>
                                                {machinesData.map((m, idx) => <option key={idx}
                                                                                      value={idx}>{m.name}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Collapse in={showMachineModels}>
                                            <Container>
                                                <Row className={"text-start"}>
                                                    <Form.Label className={"color-red"}>Model</Form.Label>
                                                </Row>
                                                {!machinesLoading && machinesData.length > 0 && selectedMachine !== "" && machinesData[parseInt(selectedMachine)].options.map((machineModel, index) =>
                                                    <InputGroup className="mb-3" key={index}>
                                                        <InputGroup.Text id="basic-addon1"
                                                                         className={"w-50"}>{machineModel.model}</InputGroup.Text>
                                                        <Form.Control
                                                            className={"text-end"}
                                                            placeholder="Cost"
                                                            aria-label="Cost"
                                                            aria-describedby="cost"
                                                            value={machineModel.cost}
                                                            isInvalid={isNaN(machineModel.cost) || parseFloat(machineModel.cost) < 0}
                                                            onChange={
                                                                event => setMachinesData(data => {
                                                                    const newOptions = data[parseInt(selectedMachine)].options.map(
                                                                        item => item.model === machineModel.model ? {
                                                                            ...item,
                                                                            cost: event.target.value
                                                                        } : item
                                                                    )

                                                                    return data.map((machineItem, index) => {
                                                                        return index === parseInt(selectedMachine) ? {
                                                                            ...machineItem,
                                                                            options: newOptions
                                                                        } : machineItem
                                                                    })
                                                                })
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {machineModel.model} needs to be a positive number.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                )}</Container>
                                        </Collapse>
                                    </Row>
                                    <hr className={"color-red"}/>
                                    <Row className={"text-end"}>
                                        <Col md={6} className={"text-md-start"}>
                                            <Button
                                                variant={"outline-danger"}
                                                className={"me-md-3"}
                                                onClick={() => {
                                                    setSelectedMachine("");
                                                    setMachinesData(machines.machinesCosts)
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                        <Col md={6} className={"text-md-end mt-md-0 mt-1"}>
                                            <Button
                                                variant={"success"}
                                                disabled={
                                                    selectedMachine === "" || (selectedMachine !== "" && machinesData.reduce(
                                                            (outerRes, machine, outerIdx) => {
                                                                return outerRes && machine.options.reduce(
                                                                    (innerRes, {cost}, innerIdx) => {
                                                                        return innerRes && parseFloat(cost) === machines.machinesCosts[outerIdx].options[innerIdx].cost
                                                                    }, true)
                                                            }, true)
                                                    )
                                                }
                                                onClick={() => {
                                                    machinesData[parseInt(selectedMachine)].options.filter((item, innerIdx) => {
                                                        return parseFloat(item.cost) !== machines.machinesCosts[parseInt(selectedMachine)].options[innerIdx].cost
                                                    }).forEach(item => applyMutation({
                                                        ...item,
                                                        type: "base_machine",
                                                        name: machines.machinesCosts[parseInt(selectedMachine)].name
                                                    }));
                                                }}
                                            >
                                                Save changes {editBaseMachineLoading &&
                                                <Spinner animation="grow" role="status" size={"sm"}
                                                         className={"ms-1"}/>}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"variations"}>
                                <Form>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>
                                                    Cost
                                                    center {(machinesLoading || optionalsLoading || optionalsLoading || optionalsLoading) &&
                                                    <Spinner animation="grow" role="status" size={"sm"}
                                                             className={"me-1 color-red"}/>}
                                                </Form.Label>
                                            </Row>
                                            <Form.Control
                                                as={"select"}
                                                type={"text"}
                                                value={selectedCostCenter}
                                                onChange={event => setSelectedCostCenter(event.target.value)}
                                            >
                                                <option value={""}>Choose the model</option>
                                                {!costCentersLoading && costCenters !== undefined && costCenters.costCenters.map((cc, idx) =>
                                                    <option key={idx} value={idx}>{cc.name}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <Row className={"mt-5"}>
                                        <Col><Form.Check
                                            inline
                                            label={"percentage".toUpperCase()}
                                            name="group1"
                                            type={"radio"}
                                            id={`inline-radio-1`}
                                            checked={selectedVariationType === "percentage"}
                                            onChange={() => setSelectedVariationType("percentage")}
                                        /></Col>
                                        <Col><Form.Check
                                            inline
                                            label={"scalar".toUpperCase()}
                                            name="group1"
                                            type={"radio"}
                                            id={`inline-radio-2`}
                                            checked={selectedVariationType === "scalar"}
                                            onChange={() => setSelectedVariationType("scalar")}
                                        /></Col>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>
                                                    Cost center
                                                </Form.Label>
                                            </Row>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="inputGroup-sizing-default">
                                                    Value
                                                </InputGroup.Text>
                                                <Form.Control
                                                    aria-label="Value"
                                                    aria-describedby="inputGroup-sizing-default"
                                                    value={selectedVariationAmount}
                                                    isInvalid={selectedVariationAmount !== "-" && isNaN(selectedVariationAmount)}
                                                    onChange={event => setSelectedVariationAmount(event.target.value)}
                                                    disabled={selectedCostCenter === ""}
                                                />
                                                {selectedVariationType === "percentage" &&
                                                    <InputGroup.Text id="inputGroup-sizing-default">
                                                        %
                                                    </InputGroup.Text>}
                                            </InputGroup>
                                            {selectedVariationAmount !== "" &&
                                                <Form.Control.Feedback type="invalid">
                                                    {costCenters && costCenters.costCenters[parseInt(selectedCostCenter)].name} needs
                                                    to be a
                                                    number.
                                                </Form.Control.Feedback>}
                                        </Form.Group>
                                    </Row>
                                    <hr className={"color-red"}/>
                                    <Row className={"text-end"}>
                                        <Col md={6} className={"text-md-start"}>
                                            <Button
                                                variant={"outline-danger"}
                                                className={"me-md-3"}
                                                onClick={() => {
                                                    setSelectedCostCenter("");
                                                    setSelectedVariationType("percentage");
                                                    setSelectedVariationAmount("");
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                        <Col md={6} className={"text-md-end mt-md-0 mt-1"}>
                                            <Button
                                                variant={"success"}
                                                disabled={selectedVariationType === "" || selectedVariationAmount === ""}
                                                onClick={() => generalEdit({
                                                    variables: {
                                                        name: costCenters.costCenters[parseInt(selectedCostCenter)].name,
                                                        costType: costCenters.costCenters[parseInt(selectedCostCenter)].type,
                                                        variationType: selectedVariationType,
                                                        amount: selectedVariationType === "percentage" ? (parseFloat(selectedVariationAmount) + 100) / 100 : parseFloat(selectedVariationAmount)
                                                    }
                                                }).then(() => {
                                                    setSelectedVariationAmount("");
                                                    setSelectedVariationType("percentage");
                                                    setSelectedCostCenter("")
                                                    switch (costCenters.costCenters[parseInt(selectedCostCenter)].type) {
                                                        case "base_aux":
                                                            selectedIdro !== "" && selectedFlow !== "" && refetchOC();
                                                            break;
                                                        case "base_machine":
                                                            selectedMachine !== "" && refetchMachines();
                                                            break;
                                                        case "global":
                                                            refetchGlobals()
                                                            break;
                                                        case "global_optional":
                                                            refetchGlobals();
                                                            break;
                                                        case "optional":
                                                            selectedIdro !== "" && selectedFlow !== "" && refetchOptionals()
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                })
                                                }
                                            >
                                                Save changes {generalEditLoading &&
                                                <Spinner animation="grow" role="status" size={"sm"}
                                                         className={"ms-1"}/>}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"deltas"}>
                                <Form>
                                    {
                                        deltasLoading &&
                                        <Row className={"d-flex align-content-center mt-3"}>
                                            <Col>
                                                <Spinner animation={"border"} className={"me-1 color-red"}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>Deltas</Form.Label>
                                            </Row>
                                            {!deltasLoading && allDeltas.map((delta, index) =>
                                                <InputGroup className="mb-3" key={index}>
                                                    <InputGroup.Text id="basic-addon1"
                                                                     className={"w-50"}>{delta.name}</InputGroup.Text>
                                                    <Form.Control
                                                        className={"text-end"}
                                                        placeholder="Delta"
                                                        aria-label="Delta"
                                                        aria-describedby="delta"
                                                        value={delta.delta}
                                                        isInvalid={isNaN(delta.delta) || parseFloat(delta.delta) < 1}
                                                        onChange={
                                                            event => setAllDeltas(dlt =>
                                                                dlt.map(o => o.name === delta.name ? {
                                                                    ...o,
                                                                    delta: event.target.value
                                                                } : o)
                                                            )
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {delta.name} delta needs to be greater than 1.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <hr className={"color-red"}/>
                                    <Row className={"text-end"}>
                                        <Col md={6} className={"text-md-start"}>
                                            <Button
                                                variant={"outline-danger"}
                                                className={"me-md-3"}
                                                onClick={() => {
                                                    setAllDeltas(originalDeltas.deltas)
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                        <Col md={6} className={"text-md-end"}>
                                            <Button
                                                variant={"success"}
                                                disabled={
                                                    !originalDeltas || allDeltas.length === 0 || originalDeltas.deltas.reduce((res, {delta}, idx) => res && delta === parseFloat(allDeltas[idx].delta), true)
                                                }
                                                onClick={() => {
                                                    allDeltas.filter((dlt, idx) => {
                                                        return parseFloat(dlt.delta) !== originalDeltas.deltas[idx].delta
                                                    })
                                                        .forEach(
                                                            dlt => {
                                                                editOptionalDelta({
                                                                    variables: {
                                                                        "name": dlt.name,
                                                                        "value": parseFloat(dlt.delta)
                                                                    }
                                                                }).then(() => refetchDeltas())
                                                            }
                                                        )
                                                    window.scrollTo(0, 0)
                                                }}
                                            >
                                                Save changes {editOptionalDeltaLoading &&
                                                <Spinner animation="grow" role="status" size={"sm"}
                                                         className={"ms-1"}/>}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"history"}>
                                <Form>
                                    <Row className={"mt-3"}>
                                        <Form.Group>
                                            <Row className={"text-start"}>
                                                <Form.Label className={"color-red"}>
                                                    Cost
                                                    center {(machinesLoading || optionalsLoading || optionalsLoading || optionalsLoading) &&
                                                    <Spinner animation="grow" role="status" size={"sm"}
                                                             className={"me-1 color-red"}/>}
                                                </Form.Label>
                                            </Row>
                                            <Form.Control
                                                as={"select"}
                                                type={"text"}
                                                value={selectedCostCenter}
                                                onChange={event => setSelectedCostCenter(event.target.value)}
                                            >
                                                <option value={""}>Choose the model</option>
                                                {!costCentersLoading && costCenters !== undefined && costCenters.costCenters.map((cc, idx) =>
                                                    <option key={idx} value={idx}>{cc.name}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Collapse
                                            in={showHistory}
                                        >
                                            <Table striped={true} hover={true}>
                                                <thead>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>Type</td>
                                                    <td>Variation</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    historyData.map(item => {
                                                        const date = new Date(parseInt(item.date))
                                                        return <tr>
                                                            <td>{ `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.value}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </Table>
                                        </Collapse>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    )
        ;
};

export default withAuthenticationRequired(BackofficeContainer, {
    onRedirecting: () => <Loading/>,
});
