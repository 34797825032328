// noinspection RequiredAttributes

import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const ButtonWithHint = ({placement, disabled = false, children, tip, handleShow, variant = "link", className = null, size = "md"}) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-${placement}`}>
          {tip}
        </Tooltip>
      }
    >
      <div style={{width: "fit-content"}}>
        <Button variant={variant} disabled={disabled} onClick={handleShow} size={size} className={className}>
          {children}
        </Button>
      </div>
    </OverlayTrigger>

  )
};

export default ButtonWithHint;
