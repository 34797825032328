import React, {Fragment, useCallback, useEffect} from 'react';
import '../../App.css';
import {usePDF} from "@react-pdf/renderer";
import {DesktopDownloadIcon} from "@primer/octicons-react"
import {Button} from "react-bootstrap";
import {getDiagram} from "../common/utils";
import EstimatePdfDocument from "./estimate/EstimatePDFGenerator";

const PDFSwitcher = (props) => {
    // eslint-disable-next-line default-case
    switch (props.type) {
        case "basic_report":
            return <EstimatePdfDocument
                data={props.data}
                title={props.filename}
                type={"basic_report"}
            />
        case "utilities":
            return <EstimatePdfDocument
                data={props.data}
                title={props.filename}
                type={"utilities"}
            />
        case "full":
            return <EstimatePdfDocument
                data={props.data}
                title={props.filename}
                type={"full"}
            />
        default:
            return <EstimatePdfDocument
                data={props.data}
                title={props.filename}
                type={"basic_report"}
            />
    }
}


const DownloadPDFButton = (props) => {

    const [{blob: baseInstance}, updatePDF] = usePDF({document: PDFSwitcher(props)});

    useEffect(()=> updatePDF())

    const download = useCallback(async () => {
        if(baseInstance !== undefined){
            let url = window.URL.createObjectURL(baseInstance);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${props.filename}-estimate${(props.type === "utilities" || props.type === "full") ? "-utilities" : ""}.pdf`;
            a.click();
        }        if (props.type === "diagrams" || props.type === "full") {
            getDiagram({
                plant: "IDRO 6",
                optionals: props.data.optionals
            }, (error) => console.log(error), `${props.filename}-diagram`)
        }
    }, [baseInstance, props.data.optionals, props.filename, props.type]);

    return (
        props.type !== "" ?
            <Button disabled={baseInstance === undefined} onClick={download}> {props.icon &&
                <Fragment><DesktopDownloadIcon/> | </Fragment>} {props.buttonText}</Button>
            :
            <Button disabled={true}> {props.icon &&
                <Fragment><DesktopDownloadIcon/> | </Fragment>} {props.buttonText}</Button>
    )
};

export default DownloadPDFButton;
