import React, {useState} from "react";
import {NavLink as RouterNavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import appConfig from "../app_conf.json"
import {
    Button,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
} from "reactstrap";

import {useAuth0} from "@auth0/auth0-react";
import configJson from "../auth_config.json";
import NavLogo from  '../assets/logo-white.png'
const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const toggle = () => setIsOpen(!isOpen);

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    return (
        <div className="nav-container">
            <Navbar className={"bg-white color-red mh-100"} light expand="lg">
                <Container>
                    <NavbarBrand><img
                        src={NavLogo}
                        width="40"
                        height="40"
                        className="d-inline-block align-top"
                        alt="Idrosistem logo"
                    /></NavbarBrand>
                    <NavbarToggler onClick={toggle}/>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto color-red ms-5" navbar>
                            {appConfig.navItems.authNotRequired.map((item, idx) => <NavItem key={idx}>
                                    <NavLink
                                        tag={RouterNavLink}
                                        className={item.className}
                                        to={item.link}
                                        exact={item.exact}
                                        activeClassName={item.activeClassName}
                                    >
                                        {item.title}
                                    </NavLink>
                                </NavItem>
                            )}
                            {isAuthenticated && appConfig.navItems.authRequired.filter(({minimumRole})=> user[configJson.roles].includes(minimumRole)).map((item, idx) =>
                                <NavItem key={idx}>
                                    <NavLink
                                        tag={RouterNavLink}
                                        className={item.className}
                                        to={item.link}
                                        exact={item.exact}
                                        activeClassName={item.activeClassName}
                                    >
                                        {item.title}
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        <Nav className="d-none d-md-block" navbar>
                            {!isAuthenticated && (
                                <NavItem className={"color-white"}>
                                    <Button
                                        id="qsLoginBtn"
                                        color="primary"
                                        className="btn-margin"
                                        onClick={() => loginWithRedirect()}
                                    >
                                        Log in
                                    </Button>
                                </NavItem>
                            )}
                            {isAuthenticated && (
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret id="profileDropDown">
                                        <img
                                            src={user.picture}
                                            alt="Profile"
                                            className="nav-user-profile rounded-circle"
                                            width="50"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>{user.name}</DropdownItem>
                                        <DropdownItem
                                            tag={RouterNavLink}
                                            to="/profile"
                                            className="dropdown-profile"
                                            activeClassName="router-link-exact-active color-white"
                                        >
                                            <FontAwesomeIcon icon="user" className="mr-3"/> Profile
                                        </DropdownItem>
                                        <DropdownItem
                                            id="qsLogoutBtn"
                                            onClick={() => logoutWithRedirect()}
                                        >
                                            <FontAwesomeIcon icon="power-off" className="mr-3"/> Log
                                            out
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            )}
                        </Nav>
                        {!isAuthenticated && (
                            <Nav className="d-md-none" navbar>
                                <NavItem className={"color-white"}>
                                    <Button
                                        id="qsLoginBtn"
                                        color="primary"
                                        block
                                        onClick={() => loginWithRedirect({})}
                                    >
                                        Log in
                                    </Button>
                                </NavItem>
                            </Nav>
                        )}
                        {isAuthenticated && (
                            <Nav
                                className="d-md-none justify-content-between"
                                navbar
                                style={{minHeight: 170}}
                            >
                                <NavItem className={"color-white"}>
                  <span className="user-info">
                    <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile d-inline-block rounded-circle mr-3"
                        width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                                </NavItem>
                                <NavItem className={"color-white"}>
                                    <FontAwesomeIcon icon="user" className="mr-3"/>
                                    <RouterNavLink
                                        to="/profile"
                                        activeClassName="router-link-exact-active color-white"
                                    >
                                        Profile
                                    </RouterNavLink>
                                </NavItem>
                                <NavItem className={"color-white"}>
                                    <FontAwesomeIcon icon="power-off" className="mr-3"/>
                                    <RouterNavLink
                                        to="#"
                                        id="qsLogoutBtn"
                                        onClick={() => logoutWithRedirect()}
                                    >
                                        Log out
                                    </RouterNavLink>
                                </NavItem>
                            </Nav>
                        )}
                    </Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default NavBar;
