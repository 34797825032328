import React, {Component} from 'react';
import '../App.css';
import Container from 'react-bootstrap/Container';
import {withAuth0} from '@auth0/auth0-react'
import Col from "react-bootstrap/Col";
import {Row, ToastContainer} from "react-bootstrap";
import {form} from "../utilitiesData/form.js"
import CalculatorComponent from "../components/UtilitiesComponent";
import Table from "react-bootstrap/Table";
import {Notification} from "../components/common/Notification";
import DownloadModal from "../components/PDF/Utilities/DownloadModalComponent";
import {
    calculate_absorbed_power,
    calculate_compressed_air,
    calculate_installed_power,
    calculate_makeup_water
} from "../components/common/utils";

class UtilitiesContainer extends Component {

    state = {
        flow: 0,
        plant: "",
        optionals: [0, 0, 0, 0],
        total_installed_power: 0,
        total_absorbed_power: 0,
        makeup_water: 0,
        compressed_air: 0,
        is_calculation_finished: false,
        notifications: []
    };

    handleCalculation = (values) => {
        let s = this.state;

        s.flow = values.flow;
        s.plant = values.plant;
        s.optionals = values.optionals;

        console.log(s);
        this.setState(s);

        s.total_installed_power = calculate_installed_power(s);
        s.total_absorbed_power = calculate_absorbed_power(s.total_installed_power);
        s.makeup_water = calculate_makeup_water(s);
        s.compressed_air = calculate_compressed_air(s);
        s.is_calculation_finished = true;

        this.setState(s);
    };


    render() {
        if (this.props.auth0.isAuthenticated)
            return (
                <Container className="mb-5 mt-3">
                    <ToastContainer position="top-end" className="p-3" containerPosition={"position-fixed"}>
                        {this.state.notifications.map((n, index) => <Notification key={index} title={n.title}
                                                                                  variant={n.variant}
                                                                                  body={n.body}/>)}
                    </ToastContainer>
                    <Container className="p-5 mb-4 bg-light rounded-3">
                        <h1 className="color-red">Utilities Calculator</h1>
                    </Container>
                    <Container>
                        <CalculatorComponent onCalculate={this.handleCalculation}/>
                    </Container>
                    {this.state.is_calculation_finished && <Container className={"mt-3"}>
                        <Row>
                            <Col>
                                <h4 className={"color-red"}>Results</h4>
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>Utility</th>
                                    <th>Result</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><strong>Total installed power</strong></td>
                                    <td>{this.state.total_installed_power} {form["INSTALLED_POWER"]["UOM"]}</td>
                                </tr>
                                <tr>
                                    <td><strong>Total absorbed power</strong></td>
                                    <td>{this.state.total_absorbed_power} {form["TOTAL_ABSORBED_POWER"]["UOM"]}</td>
                                </tr>
                                <tr>
                                    <td><strong>Make-up water</strong></td>
                                    <td>{this.state.makeup_water} {form["MAKE_UP_WATER"]["UOM"]}</td>
                                </tr>
                                <tr>
                                    <td><strong>Compressed air</strong></td>
                                    <td>{this.state.compressed_air} {form["COMPRESSED_AIR"]["UOM"]}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Container className="d-flex justify-content-end">
                            <DownloadModal configuration={this.state}/>
                        </Container>
                    </Container>
                    }

                </Container>

            );
        else return (<div/>)
    }
}

export default withAuth0(UtilitiesContainer);

