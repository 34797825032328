import React, {useState, Fragment} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import {DownloadIcon} from '@primer/octicons-react'
import DownloadPDFButton from "./DownloadPDFButton";


const DownloadModal = ({configuration}) => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <Fragment>
            <Button variant="primary"
                    onClick={() => {
                        handleShow()
                    }}
            >
                Download PDF <DownloadIcon/>
            </Button>

            <Modal size={"lg"} show={show} onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose filename</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                        }}
                    >
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Filename
                            </Form.Label>
                            <Col sm={10}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        type="text"
                                        id={"name"}
                                        name={"name"}
                                        placeholder="filename"
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        autoComplete={"off"}
                                    />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <DownloadPDFButton
                        data={configuration}
                        title={name}
                    />
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};


export default DownloadModal;