import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';
import {beautifyNumber} from "../../common/utils";

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row"
    },
    label: {
        width: '20%',
        textAlign: "left",
        color: "#a6133c",
        fontWeight: "bold",
    },
    content: {
        width: "80%",
        display: "flex",
        flexDirection: "column"
    },
    fullContent: {
        width: "80%",
        textAlign: "right"
    },
    splitContent: {
        display: "flex",
        flexDirection: "row"
    },
    centerItem: {
        width: "50%",
        textAlign: "center"
    },
    rightItem: {
        width: "50%",
        textAlign: "right"
    },
    sectionTitle: {
        paddingTop: "20px",
        color: '#000000',
        letterSpacing: 1,
        fontSize: 20,
        textAlign: 'left',
    },
    header: {
        color: "#a6133c",
        marginTop: "10px",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "row"
    },
    hr: {
        borderBottom: "1px #a6133c"
    }
});

const Estimate = ({results}) => {

    return <React.Fragment>
        <View style={styles.titleContainer}>
            <Text style={styles.sectionTitle}>Estimate ref: {results.orderRef}</Text>
        </View>
        <View style={styles.container} className={"mt-2"}>
            <Text style={styles.label}>Base configuration:</Text>
            <Text style={styles.fullContent}>{results.plant}</Text>
        </View>
        <View style={styles.container} className={"mt-2"}>
            <Text style={styles.label}>Flow</Text>
            <Text style={styles.fullContent}>{results.flow}</Text>
        </View>
        <View style={styles.header}>
            <Text style={styles.label}>Machines</Text>
            <View style={{width: "80%", display: "flex", flexDirection: "row"}}>
                <View style={styles.centerItem}>
                    <Text>Name</Text>
                </View>
                <View style={styles.rightItem}>
                    <Text>Quantity</Text>
                </View>
            </View>
        </View>
        <View style={styles.container} className={"mt-2"}>
            <Text style={styles.label}/>
            <View style={styles.content}>
                {results.configuration.map((conf, idx) => <View key={idx} style={styles.splitContent}>
                        <Text style={styles.centerItem}>{conf.name}</Text>
                        <Text style={styles.rightItem}>{conf.quantity}</Text>
                    </View>
                )}
            </View>
        </View>
        <View style={styles.header}>
            <Text style={styles.label}>Optionals</Text>
            <View style={{width: "80%", display: "flex", flexDirection: "row"}}>
                <View style={styles.centerItem}>
                    <Text>Name</Text>
                </View>
                <View style={styles.rightItem}>
                    <Text>Price</Text>
                </View>
            </View>
        </View>
        <View style={styles.container} className={"mt-2"}>
            <Text style={styles.label}></Text>
            <View style={styles.content}>
                {results.optionals.map((opt, idx) => <View key={idx} style={styles.splitContent}>
                        <Text style={styles.centerItem}>{opt.name}</Text>
                        <Text style={styles.rightItem}>{beautifyNumber(opt.price)}</Text>
                    </View>
                )}
            </View>
        </View>
        <View style={styles.hr}/>
        <View style={styles.container} className={"mt-2"}>
            <Text style={styles.label}>Base configuration</Text>
            <Text style={styles.fullContent}>{beautifyNumber(results.estimate.baseTotal)}</Text>
        </View>
        <View style={styles.container}>
            <Text style={styles.label}>Optionals</Text>
            <Text style={styles.fullContent}>{beautifyNumber(results.estimate.optionalsTotal)}</Text>
        </View>
        <View style={styles.container}>
            <Text style={styles.label}>Estimate total</Text>
            <Text style={styles.fullContent}>{beautifyNumber(results.estimate.total)}</Text>
        </View>
    </React.Fragment>
};

export default Estimate