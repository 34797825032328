import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Popconfirm, Table} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

const EditableContext = React.createContext(null);

const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
const ExtraCostsTable = ({costs, setCosts}) => {
    const [count, setCount] = useState(2);
    const handleDelete = (key) => {
        const newData = costs.filter((item) => item.key !== key);
        costs(newData);
    };
    const defaultColumns = [
        {
            title: 'Cost',
            dataIndex: 'cost',
            width: '45%',
            editable: true,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            width: '45%',
            editable: true,
        },
        {
            title: '',
            dataIndex: '',
            render: (_, record) =>
                costs.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <DeleteOutlined/>
                    </Popconfirm>
                ) : null,
        },
    ];
    const handleAdd = () => {
        const newData = {
            key: count,
            cost: 'Click to edit',
            value: 'Click to edit'
        };
        setCosts([...costs, newData]);
        setCount(count+1)
    };
    const handleSave = (row) => {
        const newData = [...costs];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setCosts(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    return (
        <div>
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={costs}
                columns={columns}
                footer={() =>
                    <div className={'d-inline-flex justify-content-end w-100'}>
                        <Button
                            onClick={handleAdd}
                            type="primary"
                        >
                            Add a cost
                        </Button>
                    </div>
                }
            />
        </div>
    );
};
export default ExtraCostsTable;