import React from 'react';
import '../../../App.css';
import {PDFDownloadLink} from "@react-pdf/renderer";
import Spinner from 'react-bootstrap/Spinner';
import PdfDocument from "./PDFGenerator";



const DownloadPDFButton = ({data, title}) => {

    return (
        <PDFDownloadLink
            document={
                <PdfDocument
                    data={data}
                    title={{title}}
                />}
            fileName={`${title}.pdf`}
            className={"btn btn-link"}
        >
            {({loading}) =>
                loading ? <Spinner animation="border" variant="primary"/> : "Download"
            }
        </PDFDownloadLink>
    )
}

export default DownloadPDFButton;
