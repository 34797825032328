import React, {Fragment, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {DownloadIcon} from '@primer/octicons-react'
import ButtonWithHint from "../common/ButtonWithHintComponent";
import DownloadPDFButton from "./DownloadPDFButton";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";


const MultipleDownloadModal = ({data, defaultName, docTypes, buttonText}) => {
  const [show, setShow] = useState(false);
  const [filename, setFilename] = useState(defaultName);
  const [docType, setDocType] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <ButtonWithHint
        placement={"top"}
        tip={"Scarica PDF"}
        handleShow={handleShow}
        variant={"success"}
      >
        <DownloadIcon/> | {buttonText}
      </ButtonWithHint>


      <Modal size={"lg"} show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Nome file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Filename
              </Form.Label>
              <Col sm={10}>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    id={"name"}
                    name={"name"}
                    placeholder="filename"
                    onChange={e => setFilename(e.target.value)}
                    value={filename}
                    autoComplete={"off"}
                  />
                  <InputGroup.Text id="basic-addon2">.pdf</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Tipo file
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  type="text"
                  id={"file_type"}
                  name={"file_type"}
                  onChange={(event) => setDocType(event.target.value)}
                  value={docType}
                >
                  <option key={"option-null"} value={""}> Choose filetype</option>
                  {
                    docTypes.map(
                      (type, index) => <option key={"option-" + index}
                                               value={type.code}>{type.name}</option>
                    )
                  }
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <DownloadPDFButton
            data={data}
            filename={filename}
            type={docType}
            buttonText={"Download"}
          />
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default MultipleDownloadModal;
