import React from "react";
import {Router, Route, Switch} from "react-router-dom";
import configJson from "./auth_config.json";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import BackofficeContainer from "./views/BackofficeContainer";
import UtilitiesContainer from "./views/UtilitiesContainer";
import ConfiguratorContainer from "./views/ConfiguratorContainer";
import DiagramsContainer from "./views/DiagramsContainer";
import {useAuth0} from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import {Container} from "react-bootstrap";
import {OfferContainer} from "./views/OfferContainer";

initFontAwesome();

const App = () => {
    const {isLoading, error, user} = useAuth0();

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <Loading/>;
    }
    return (
        <Router history={history}>
            <div id="app" className="d-flex flex-column" style={{height: '100vh'}}>
                <NavBar/>
                <Container className={'flex-shrink-0' +
                    ' flex-grow-1'}>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/profile" component={Profile}/>
                        {user && user[configJson.roles].includes("customer") &&
                            <Route path="/utilities" component={UtilitiesContainer}/>}
                        {user && user[configJson.roles].includes("operator") &&
                            <Route path="/backoffice" component={BackofficeContainer}/>}
                        {user && user[configJson.roles].includes("customer") &&
                            <Route path="/configurator" component={ConfiguratorContainer}/>}
                        {user && user[configJson.roles].includes("operator") &&
                            <Route path="/offer" component={OfferContainer}/>}
                        {user && user[configJson.roles].includes("customer") &&
                            <Route path="/diagrams" component={DiagramsContainer}/>}
                    </Switch>
                </Container>
                <Footer/>
            </div>
        </Router>
    );
};

export default App;
/*
* */