import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {form} from "../../../utilitiesData/form";

const borderColor = '#9D0038';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#9D0038',
        backgroundColor: '#9D0038',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        maxHeight: "20px",
        color: "white"
    },
    utility: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
    },
    result: {
        width: '60%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: "5px"

    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#9D0038',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    sectionTitle: {
        paddingTop: "20px",
        color: '#000000',
        letterSpacing: 1,
        fontSize: 20,
        textAlign: 'left',
    },

});

const ResultsPDF = ({results, header = "Results"}) => (
    <React.Fragment>
        <View style={styles.titleContainer}>
            <Text style={styles.sectionTitle}>{header}:</Text>
        </View>
        <View style={styles.container}>
            <Text style={styles.utility}>Utility</Text>
            <Text style={styles.result}>Result</Text>
        </View>
        <View style={styles.row} key={"INSTALLED_POWER"}>
            <Text style={styles.utility}>Total Installed Power</Text>
            <Text style={styles.result}>{results.total_installed_power + " " + form["INSTALLED_POWER"]["UOM"]}</Text>
        </View>
        <View style={styles.row} key={"TOTAL_ABSORBED_POWER"}>
            <Text style={styles.utility}>Total Absorbed Power</Text>
            <Text style={styles.result}>{results.total_absorbed_power + " " + form["TOTAL_ABSORBED_POWER"]["UOM"]}</Text>
        </View>
        <View style={styles.row} key={"MAKE_UP_WATER"}>
            <Text style={styles.utility}>Makeup Water</Text>
            <Text style={styles.result}>{results.makeup_water + " " + form["MAKE_UP_WATER"]["UOM"]}</Text>
        </View>
        <View style={styles.row} key={"COMPRESSED_AIR"}>
            <Text style={styles.utility}>Compressed Air</Text>
            <Text style={styles.result}>{results.compressed_air + " " + form["COMPRESSED_AIR"]["UOM"]}</Text>
        </View>
    </React.Fragment>
);

export default ResultsPDF