/* eslint-disable no-eval */
import {form} from "../../utilitiesData/form";

export const getDateToString = (param) => {

    if (param === null) return null;

    let date = new Date(param.toString());
    if (isNaN(date.getTime())) {
        let _date = param.split(" ")[0].split("-");
        date = new Date(_date[1] + "/" + _date[2] + "/" + _date[0]);
    }
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

};

export const getYMD = (date, separator) => {
    let parts = date.split(separator)
    if (parts[0].length === 4)
        return date
    else
        return parts[2] + separator + parts[1] + separator + parts[0]
}

export const getDMY = (date, separator) => {
    let parts = date.split(separator)
    if (parts[2].length === 4)
        return date
    else
        return parts[2] + separator + parts[1] + separator + parts[0]
}


export const getDateToStringCompact = (param, separator = "/", reverse = false, fullYear) => {

    if (param === null) return null;

    let date = new Date(param.toString());
    if (isNaN(date.getTime())) {
        let _date = param.split(" ")[0].split("-");
        date = new Date(_date[1] + separator + _date[2] + separator + _date[0]);
    }
    let d = date.getDate();
    let m = date.getMonth() + 1
    let Y = date.getFullYear().toString()
    let y = Y.substring(Y.length - 2)

    if (reverse) {
        return (fullYear ? Y : y) + separator + m + separator + d;
    } else {
        return d + separator + m + separator + (fullYear ? Y : y);
    }

};

//reverse -> YMD
export const isValidDate = (date, separator = "/", reverse = false) => {
    let date_components;
    try {
        date_components = date.split(separator);
        if (!reverse)
            if ((Number.parseInt(date_components[0]) > 0 && Number.parseInt(date_components[0]) < 32)
                && (Number.parseInt(date_components[1]) > 0 && Number.parseInt(date_components[1]) < 13)
                && (date_components[2].length === 4)
            ) {
                new Date(date_components[2], date_components[1], date_components[0]);
            } else {
                return false;
            }
        else {
            if ((Number.parseInt(date_components[2]) > 0 && Number.parseInt(date_components[2]) < 32)
                && (Number.parseInt(date_components[1]) > 0 && Number.parseInt(date_components[1]) < 13)
                && (date_components[0].length === 4)
            ) {
                new Date(date_components[0], date_components[1], date_components[2]);
            } else {
                return false;
            }
        }
    } catch (e) {
        return false;
    }
    return true
};


export const getIsoString = (date, separator = "/", YMD = true) => {

    let dateComponents = date.split(separator);


    return YMD ? new Date(dateComponents[2], dateComponents[1] - 1, dateComponents[0]).toISOString() :
        new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2]).toISOString()
};


export const isValidInput = (input) => {

    let result = true;

    Object.keys(input).forEach(key => input[key] === null ? null : result = false);

    return result;

};

export const isFormFilled = (input, nonRequiredElements) => {

    let counter = 0;
    Object.keys(input).forEach(key => input[key] === "" ? nonRequiredElements.includes(key) ? counter++ : null : counter++);

    return counter === Object.keys(input).length;

};

export const smartDateChange = (value, separator = "-") => {

    const parts = value.split(separator)

    const lenght = parts.length
    if (lenght < 3) {
        if (lenght > 0) {
            if (parts[lenght - 1].length === 2) value += separator
        }
    }

    return value
};

export const beautifyNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const calculate_installed_power = (s) => {

    let formula = form["INSTALLED_POWER"]["formulas"][s.plant]["formula"];

    formula = formula.replaceAll("Q", s.flow);

    const partial_installed_power = eval(formula);

    let optionals_installed_power = 0;

    Object.keys(s.optionals).forEach((o) => {
        if (s.optionals[o] === true || s.optionals === 1) {
            let formula = form["INSTALLED_POWER"]["formulas"][s.plant]["optionals"][o];
            if (o === "UVC") {
                let absorbed_power = get_absorbed_power_UVC(s);

                formula = formula.replaceAll("ABSORBED_POWER", absorbed_power)
            } else if (o === "OZONE") {
                formula = formula.replaceAll("Q", s.flow)
            }

            optionals_installed_power += eval(formula);

        }
    });


    return Math.ceil((partial_installed_power + optionals_installed_power) * 10) / 10;

};

export const get_absorbed_power_UVC = (s) => {
    let ap = "";
    Object.keys(form["ABSORBED_POWER"]["values"]).forEach((key) => {
        if (s.flow >= Number(key)) {
            ap = form["ABSORBED_POWER"]["values"][key];
        }
    });

    return ap
};

export const calculate_absorbed_power = (total_installed_power) => {
    let formula = form["TOTAL_ABSORBED_POWER"]["formula"].replaceAll("INSTALLED_POWER", total_installed_power);

    return Math.ceil(eval(formula) * 10) / 10;
};

export const calculate_makeup_water = (s) => {
    let formula;
    if (s.optionals["ZD"] && s.optionals["ZD"] !== -1) {
        formula = "0";
    } else if (s.optionals["BWR"] && s.optionals["BWR"] !== -1) {
        formula = form["MAKE_UP_WATER"]["formulas"][s.plant]["optionals"]["BWR"].replaceAll("Q", s.flow)
    } else {
        formula = form["MAKE_UP_WATER"]["formulas"][s.plant]["formula"].replaceAll("Q", s.flow)
    }

    return Math.ceil(eval(formula) * 10) / 10;
};

export const calculate_compressed_air = (s) => {

    let formula = (form["COMPRESSED_AIR"]["formulas"][s.plant].replaceAll("Q", s.flow));

    return Math.ceil(eval(formula) * 10) / 10;

};

export const getDiagram = ({plant, optionals}, setNotifications, name = "") => {
    let selectedOptional;
    if (optionals['BWR'] === 1 || optionals['BWR'])
        selectedOptional = "BWR"
    if (optionals['ZD'] === 1 || optionals['ZD'])
        selectedOptional = "ZD"

    fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADDRESS}/diagram?idro=${plant}${selectedOptional ? `&optionals=${selectedOptional}` : ''}`)
        .then(response => {
            if (response.status !== 200)
                throw new Error();
            else
                return response;
        })
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = `${name !== "" ? name : plant}${selectedOptional ? `-${selectedOptional}` : ''}.pdf`;
                a.click();
            });
        })
        .catch(() => {
            setNotifications((notifications) => [...notifications, {
                title: "Error during data fetch!",
                body: "If the error persists, please contact Idrosistem.",
                variant: "danger"
            }])
        });
}
