import React from "react";
import ResultsPDF from "../Utilities/ResultsPDF"

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import logo from "../img/logo_full.png"
import Estimate from "./estimate";


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    logo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: "150px"
    },
    titleContainer: {
        flexDirection: 'row',
    },
    reportTitle: {
        color: '#000000',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
    }

});

const Title = ({title}) => {
        return (
            <View style={styles.titleContainer} className={"mt-5"}>
                <Text style={styles.reportTitle}>{title.title}</Text>
            </View>
        )
    }
;


const EstimatePdfDocument = (props) => {

    console.log(props, (props.type === "utilities" || props.type === "full"));

    const utilitiesData = {
        total_installed_power: props.data.total_installed_power,
        total_absorbed_power: props.data.total_absorbed_power,
        makeup_water: props.data.makeup_water,
        compressed_air: props.data.compressed_air
    };

    return (
        <Document>
            <Page key={"page-0"} style={styles.page}>
                <Image style={styles.logo} src={logo}/>
                <Title title={props.title}/>
                <Estimate results={props.data}/>
                {(props.type === "utilities" || props.type === "full") && <ResultsPDF results={utilitiesData} header={"Utilities"}/>}
            </Page>
        </Document>
    );
};

export default EstimatePdfDocument;