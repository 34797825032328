import React, {Fragment, useCallback, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import CryptoJS from 'crypto-js';

import {Alert, Button, Col, Collapse, Container, Form, Row, Spinner} from "react-bootstrap";
import {useLazyQuery, useMutation} from "@apollo/client";
import {BASE_AUX_VARIATION, FLOWS_QUERY, PDF_QUERY} from "../apollo/apollo-queries";
import {AlertIcon, DashIcon, PlusIcon} from "@primer/octicons-react";
import InputGroup from "react-bootstrap/InputGroup";
import {Steps} from 'antd';
import ExtraCostsTable from "../components/ExtraCostsTable";
import ExtraInfoTable from "../components/ExtraInfo";

const itemsArray = [
    {label: "Civil works & mezzanine dimensional drawings", value: "INCLUDED"},
    {label: "Not continuous Supervision to erections", value: "OPTIONAL"},
    {label: "Pipes of connection between the units", value: "INCLUDED"},
    {label: "Commissioning and start-up of the plant", value: "INCLUDED"},
    {label: "Instruction manual in other language that is not English.", value: "EXCLUDED"},
    {label: "Civil works of any type", value: "EXCLUDED"},
    {label: "Cables and cables trays", value: "EXCLUDED"},
    {label: "Flight ticket for our technicians", value: "EXCLUDED"},
    {label: "Local transportation for our technicians", value: "EXCLUDED"},
    {label: "Hotel costs for our technicians", value: "EXCLUDED"},
    {label: "Pocket money (€40 per day)", value: "EXCLUDED"},
    {label: "Cranes & forklifts for unloading the equipment in the site", value: "EXCLUDED"},
    {label: "Electric line and pressured air line (min 6 bar) up to the central panel board", value: "EXCLUDED"},
    {label: "Make up water line up to tank “T2” & “T3” (see UTILITIES for specifications);", value: "EXCLUDED"},
    {label: "Tanks for chemicals", value: "EXCLUDED"},
    {label: "HPP feeding pump", value: "EXCLUDED"},
    {label: "Profibus cable between our PLC and the spunlace line", value: "EXCLUDED"},
    {label: "LAN Ethernet connection", value: "EXCLUDED"},
    {label: "Mezzanine for our equipment", value: "EXCLUDED"},
    {label: "Local taxes, custom clearance fees, etc", value: "EXCLUDED"}
];

export const OfferContainer = () => {
    const {user} = useAuth0();
    const [formData, setFormData] = useState({
        OFFERNUMBER: {label: 'OFFERNUMBER', value: ''},
        CUSTOMER: {label: 'CUSTOMER', value: ''},
        REF: {label: 'REF', value: ''},
        RAWMATERIALS: {label: 'RAWMATERIALS', value: ''},
        INSTALLATIONSITE: {label: 'INSTALLATIONSITE', value: ''},
        ETF: {label: 'ETF', value: ''},
        ELECTRICALSPECIFICATION: {label: 'ELECTRICALSPECIFICATION', value: ''},
        PAYMENT: {label: 'PAYMENT', value: ''},
        DATE: {label: 'DATE', value: ''},
        DELIVERY: {label: 'DELIVERY', value: ''},
    });
    const [inEx, setInEx] = useState(itemsArray);
    const [newExtra, setNewExtra] = useState("");
    const [discount, setDiscount] = useState('');
    const [current, setCurrent] = useState(0);
    const [costs, setCosts] = useState([]);
    const [info, setInfo] = useState([]);
    const [getPDF, {
        loading: pdfLoading
    }] = useLazyQuery(PDF_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onCompleted: async data => {
            try {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                const uint8Array = Uint8Array.from(atob(data.pdf), (c) => c.charCodeAt(0));
                let blob = new Blob([uint8Array], {type: "octet/stream"}),
                    url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `${formData.OFFERNUMBER.value}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);

            } catch (e) {
                console.log(e);
            }

        },
        onError: (error) => {
            debugger;
        },
    });
    const [estimate, setEstimate] = useState({});
    const [machines, setMachines] = useState([])

    const getObjectFromLocalStorage = useCallback((key) => {
        // Retrieve the encrypted object from localStorage
        const encryptedObject = localStorage.getItem(key);

        if (encryptedObject) {
            // Decrypt the object when retrieving it
            const decryptedObject = CryptoJS.AES.decrypt(
                encryptedObject,
                user.email
            ).toString(CryptoJS.enc.Utf8);

            // Parse the decrypted JSON string back to an object
            const parsedData = JSON.parse(decryptedObject);
            setEstimate(parsedData);
        }
    }, [user.email]);

    useEffect(() => {
        getObjectFromLocalStorage('estimate');
    }, [getObjectFromLocalStorage])

    useEffect(() => {
        if (estimate.selectedConfiguration && estimate.optionals)
            setMachines([
                ...estimate.selectedConfiguration?.map(({name}) => name),
                ...estimate.optionals?.map(({name}) => name)
            ]);
    }, [estimate])

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: {...formData[name], value},
        });
    };

    const handleRadioChange = (idx, value) => {
        const tmp = [...inEx];
        tmp[idx].value = value
        setInEx(tmp);
    };

    const specialCharactersConverter = (input) => {
        const latexReplacements = {
            '&': '\\&',
            '%': '\\%',
            '$': '\\$',
            '#': '\\#',
            '_': '\\_',
            '~': '\\textasciitilde{}',
            '^': '\\textasciicircum{}',
            '€': '\\euro{}', // Euro symbol
        };

        // Add more replacements as needed

        return input.replace(/[&%$#_{}~^\\€]/g, match => {
            console.log(match)
            return latexReplacements[match]
        });
    }


    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        // Do something with the form data, e.g., send it to the server
        const variables = {
            "conf": estimate.selectedConfiguration.map(({name, quantity, model}) => {
                return {name, quantity, model}
            }),
            "model": estimate.model,
            "flow": parseFloat(estimate.flow),
            "offerParams": Object.keys(formData).map(key => {
                return {
                    key: specialCharactersConverter(formData[key].label),
                    value: specialCharactersConverter(formData[key].value)
                }
            }),
            "optionals": estimate.optionals,
            "inEx": inEx.map(item => {
                return {'key': specialCharactersConverter(item.label), "value": item.value}
            }),
            "utilities": Object.keys(estimate.utilities).map(key => {
                return {key: key, value: estimate.utilities[key].toString()}
            }),
            discount,
            "extraInfo": info.map(item => {
                return {key: item.component, value: specialCharactersConverter(item.info)}
            }),
            "extraCosts": costs.map(item => {
                return {key: specialCharactersConverter(item.cost), value: item.value}
            })
        }

        await getPDF({variables});
    }, [costs, discount, estimate.flow, estimate.model, estimate.optionals, estimate.selectedConfiguration, estimate.utilities, formData, getPDF, inEx, info]);

    const trim = (e) => {
        const {name, value} = e.target;
        return {target: {name, value: value.replaceAll(/\s/g, '')}};
    };
    return (<Container className="mb-5 mt-3">
        <Container className={"text-center d-flex flex-column "}>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={2} className={"text-start"}>
                        <Steps
                            size="small"
                            current={current}
                            direction="vertical"
                            items={[
                                {
                                    title: 'Header',
                                    onClick: () => setCurrent(0)
                                },
                                {
                                    title: 'Services',
                                    onClick: () => setCurrent(1)
                                },
                                {
                                    title: 'Extra comments',
                                    onClick: () => setCurrent(2)
                                },
                                {
                                    title: 'Extra informations',
                                    onClick: () => setCurrent(3)
                                },
                            ]}
                        />
                    </Col>
                    <Col md={8}>
                        {current === 0 && <Fragment>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formOFFERNUMBER">
                                        <Form.Label className={"color-red mt-2"}>OFFER NUMBER:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="OFFERNUMBER"
                                            value={formData.OFFERNUMBER.value}
                                            onChange={($event) => {
                                                handleInputChange(trim($event))
                                            }}
                                            placeholder="Enter offer number"

                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formCUSTOMER">
                                        <Form.Label className={"color-red mt-2"}>CUSTOMER:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="CUSTOMER"
                                            value={formData.CUSTOMER.value}
                                            onChange={handleInputChange}
                                            placeholder="Enter CUSTOMER"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formREF">
                                        <Form.Label className={"color-red mt-2"}>REFERENT:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="REF"
                                            value={formData.REF.value}
                                            onChange={handleInputChange}
                                            placeholder="Enter REF"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formRAWMATERIALS">
                                        <Form.Label className={"color-red mt-2"}>RAW MATERIALS:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="RAWMATERIALS"
                                            value={formData.RAWMATERIALS.value}
                                            onChange={handleInputChange}
                                            placeholder="Enter RAWMATERIALS"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formINSTALLATIONSITE">
                                        <Form.Label className={"color-red mt-2"}>INSTALLATION SITE:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="INSTALLATIONSITE"
                                            value={formData.INSTALLATIONSITE.value}
                                            onChange={handleInputChange}
                                            placeholder="Enter INSTALLATIONSITE"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formETF">
                                        <Form.Label className={"color-red mt-2"}>ELECTRICAL TENSION
                                            FREQUENCY:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="ETF"
                                            value={formData.ETF.value}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select tension frequency</option>
                                            <option value="3x400 V - 50 Hz">3x400 V - 50 Hz</option>
                                            <option value="3x440 V - 60 Hz">3x440 V - 60 Hz</option>
                                            <option value="220 V - 50 Hz">220 V - 50 Hz</option>
                                            <option value="220 V - 60 Hz">220 V - 60 Hz</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formELECTRICALSPECIFICATION">
                                        <Form.Label className={"color-red mt-2"}>ELECTRICAL SPECIFICATION:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="ELECTRICALSPECIFICATION"
                                            value={formData.ELECTRICALSPECIFICATION.value}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select electrical specifications</option>
                                            <option value="Standard Idrosistem">Standard Idrosistem</option>
                                            <option value="Customer">Customer</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formPAYMENT">
                                        <Form.Label className={"color-red mt-2"}>PAYMENT:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="PAYMENT"
                                            value={formData.PAYMENT.value}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select payment option</option>
                                            <option value="tnw">Standard Truetzchler</option>
                                            <option value="generic">Generic</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formDATE">
                                        <Form.Label className={"color-red mt-2"}>DATE:</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="DATE"
                                            value={formData.DATE.value}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formDELIVERY">
                                        <Form.Label className={"color-red mt-2"}>DELIVERY:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="DELIVERY"
                                            value={formData.DELIVERY.value}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select delivery option</option>
                                            <option value="EX-WORKS – our warehouse">EX-WORKS – our warehouse</option>
                                            <option value="DAP – city">DAP – city</option>
                                            <option value="FOB – port">FOB – port</option>
                                            <option value="DDP – customer">DDP – customer</option>
                                            <option value="Generic">CIF – port</option>
                                        </Form.Control>
                                    </Form.Group>

                                </Col>
                            </Row>
                        </Fragment>}
                        {current === 1 && <Fragment>
                            <Row>
                                {inEx.map((item, index) => (
                                    <Row key={index}>
                                        <Col>
                                            <Form.Label>{item.label}</Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                inline
                                                label="INCLUDED"
                                                name={`radio-${index}`}
                                                checked={item.value === 'INCLUDED'}
                                                onChange={(event) => {
                                                    if (event.target.checked)
                                                        handleRadioChange(index, 'INCLUDED')
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                inline
                                                label="EXCLUDED"
                                                name={`radio-${index}`}
                                                checked={item.value === 'EXCLUDED'}
                                                onChange={(event) => {
                                                    if (event.target.checked)
                                                        handleRadioChange(index, 'EXCLUDED')
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                inline
                                                label="OPTIONAL"
                                                name={`radio-${index}`}
                                                checked={item.value === 'OPTIONAL'}
                                                onChange={(event) => {
                                                    if (event.target.checked)
                                                        handleRadioChange(index, 'OPTIONAL')
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </Row>
                            <Row className={"mt-3"}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Type to add extra item"
                                        aria-label="Type to add extra item"
                                        aria-describedby="basic-addon2"
                                        value={newExtra}
                                        onChange={(event) => setNewExtra(event.target.value)}
                                    />
                                    <Button type={"button"}
                                            onClick={() => {
                                                setInEx((prev) => [...prev, {label: newExtra, value: "INCLUDED"}])
                                                setNewExtra("");
                                            }}
                                            variant="outline-secondary"
                                            id="button-addon2"
                                            disabled={newExtra === ""}
                                    >
                                        Add
                                    </Button>
                                </InputGroup>
                            </Row>
                        </Fragment>}
                        {current === 2 && <Fragment>
                            <div className={"d-inline-flex w-100 mt-3 justify-content-start"}>
                                <h5 className={'color-red'}>Additional costs:</h5>
                            </div>
                            <Row>
                                <ExtraCostsTable
                                    costs={costs}
                                    setCosts={setCosts}
                                ></ExtraCostsTable>
                            </Row>
                            <div className={"d-inline-flex w-100 mt-3 justify-content-start"}>
                                <h5 className={'color-red'}>Discount:</h5>
                            </div>
                            <Row>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Discount %"
                                        aria-label="Discount %"
                                        aria-describedby="basic-addon2"
                                        value={discount}
                                        onChange={(event) => setDiscount(event.target.value)}
                                    />
                                </InputGroup>
                            </Row>
                        </Fragment>}
                        {current === 3 && <Fragment>
                            <div className={"d-inline-flex w-100 mt-3 justify-content-start"}>
                                <h5 className={'color-red'}>Additional information:</h5>
                            </div>
                            <Row>
                                <ExtraInfoTable
                                    machines={machines}
                                    info={info}
                                    setInfo={setInfo}
                                >
                                </ExtraInfoTable>
                            </Row>
                        </Fragment>}
                    </Col>
                    <Col md={2}/>
                </Row>
                <Row className={'mt-3'}>
                    <Col md={2} className={"text-start"}>
                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col>
                                {
                                    current !== 0 && <div className={'d-flex justify-content-start'}>
                                        <Button variant="success" type="button"
                                                onClick={() => setCurrent(current => current - 1)}>
                                            Previous
                                        </Button>
                                    </div>
                                }
                            </Col>
                            <Col>
                                {
                                    current !== 3 && <div className={'d-flex justify-content-end'}>
                                        <Button variant="success" type="button"
                                                onClick={() => setCurrent(current => current + 1)}>
                                            Next
                                        </Button>
                                    </div>
                                }
                                {
                                    current === 3 && <div className={'d-flex justify-content-end'}>
                                        <Button variant="success" type="submit">
                                            {pdfLoading ? "Loading..." : "Get PDF"}
                                        </Button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}/>
                </Row>
            </Form>
        </Container>
    </Container>);
};
export default withAuthenticationRequired(OfferContainer, {
    onRedirecting: () => <Loading/>,
});

