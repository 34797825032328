import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';
import {form} from "../../../utilitiesData/form";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    desc: {
        width: '40%',
        fontStyle: 'bold',
    },
    value: {
        width: '60%',
        paddingLeft: "5px"
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        color: "white"
    },
    sectionTitle: {
        color: '#000000',
        letterSpacing: 1,
        fontSize: 20,
        textAlign: 'left',
    },
});

const ConfigurationPDF = ({configuration}) => {

        let installedOptionals = [];

        Object.keys(configuration.optionals).forEach((key) => {
            if (configuration.optionals[key] === true) {
                installedOptionals.push(key)
            }
        });

        if (installedOptionals.length === 0) {
            installedOptionals.push("None")
        }

        let date = new Date();
        const creationDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

        return (
            <React.Fragment>
                <View style={styles.titleContainer}>
                    <Text style={styles.sectionTitle}>Plant Configuration:</Text>
                </View>
                <View style={styles.row} key={"date"}>
                    <Text style={styles.desc}>Date:</Text>
                    <Text
                        style={styles.value}>{creationDate}</Text>
                </View>
                <View style={styles.row} key={"plant"}>
                    <Text style={styles.desc}>Base Configuration</Text>
                    <Text
                        style={styles.value}>{configuration.plant + " " + form["INSTALLED_POWER"]["formulas"][configuration.plant]["base_specification"]}</Text>
                </View>
                <View style={styles.row} key={"flow"}>
                    <Text style={styles.desc}>Flow Rate m³/h</Text>
                    <Text style={styles.value}>{configuration.flow}</Text>
                </View>
                <View style={styles.row} key={"optionals"}>
                    <Text style={styles.desc}>Installed Optionals</Text>
                    <Text style={styles.value}>{installedOptionals.toString()}</Text>
                </View>
            </React.Fragment>
        )
    }
;

export default ConfigurationPDF