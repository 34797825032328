import React, {useState} from 'react';
import Toast from 'react-bootstrap/Toast';

export const Notification = ({title, variant, body}) => {
    const [show, setShow] = useState(true);
    return (
        <Toast onClose={() => setShow(false)} show={show} delay={7000} autohide bg={variant.toLowerCase()}>
            <Toast.Header>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{title}</strong>
                <small>Adesso</small>
            </Toast.Header>
            <Toast.Body className={"text-white"}>{body}</Toast.Body>
        </Toast>
    );
}
