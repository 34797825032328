import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";

import {Container, ToastContainer} from "react-bootstrap";
import {Notification} from "../components/common/Notification";
import CalculatorComponent from "../components/UtilitiesComponent";
import {getDiagram} from "../components/common/utils";

export const DiagramsContainer = () => {
    const [notifications, setNotifications] = useState([]);


    return (
        <Container className="mb-5 mt-3">
            <ToastContainer position="top-end" className="p-3" containerPosition={"position-fixed"}>
                {notifications.map((n, index) => <Notification key={index} title={n.title} variant={n.variant}
                                                               body={n.body}/>)}
            </ToastContainer>
            <Container className="p-5 mb-4 bg-light rounded-3">
                <h1 className="color-red">Diagrams</h1>
            </Container>
            <Container className={"text-center d-flex flex-column color-red"}>
                <CalculatorComponent onCalculate={(data) => {
                    getDiagram(data, setNotifications)
                }} withFlow={false} buttonText={"Download"}/>
            </Container>
        </Container>
    );
};

export default withAuthenticationRequired(DiagramsContainer, {
    onRedirecting: () => <Loading/>,
});
