import {gql} from '@apollo/client';

export const GLOBALS_QUERY = gql`
    query GlobalCosts {
        globalCosts {
            delta
            name
            type
            value_type
            value
        }
    }
`;

export const MACHIINES_QUERY = gql`
    query MachinesCosts {
        machinesCosts {
            name
            type
            options {
                model
                cost
            }
        }
    }
`;

export const DELTA_QUERY = gql`
    query Delta($model: String!) {
        delta(model: $model)
    }
`;

export const ALL_DELTAS = gql`
    query Deltas {
        deltas {
            name
            delta
        }
    }
`;

export const OTHER_COSTS_QUERY = gql`
    query OtherCosts($model: String!, $flow: Int!) {
        otherCosts(model: $model, flow: $flow) {
            type
            name
            model
            flow
            cost
        }
    }
`;

export const OPTIONALS_QUERY = gql`
    query Optionals($model: String!, $flow: Int!) {
        optionals(model: $model, flow: $flow) {
            price
            name
            mutual_exclusion
            description
            um
            quantity
            type
            delta
            editable
        }
    }
`

export const FLOWS_QUERY = gql`
    query ConfData($model: String!) {
        flows(model: $model)
    }
`

export const CONFIGURATION_OPTIONAL_QUERY = gql`
    query ConfigurationData($model: String!, $flow: Int!) {
        configuration(model: $model, flow: $flow) {
            name
            quantity
            model
            modifiable
        }
        optionals(model: $model, flow: $flow) {
            price
            name
            mutual_exclusion
            description
            um
            quantity
            editable
        }
    }
`;

export const ESTIMATE_QUERY = gql`
    query Estimate($conf: [CostSpecInput]!, $model: String!, $flow: Int!, $optionals: [OptionalSpecInput]!) {
        estimate(conf: $conf, model: $model, flow: $flow, optionals: $optionals) {
            model
            baseTotal
            optionals {
                optional
                cost
            }
            total
            optionalsTotal
        }
    }
`;

export const HISTORY_QUERY = gql`
query HistoryOfChanges($name: String!) {
  historyOfChanges(name: $name) {
    date
    name
    type
    value
  }
}
`;

export const GLOBAL_COST_VARIATION = gql`
    mutation ApplyGlobalCostVariation($name: String!, $amount: Float, $valueType: GlobalTypeEnum) {
        applyGlobalCostVariation(name: $name, amount: $amount, value_type: $valueType)
    }`;

export const BASE_AUX_VARIATION = gql`
    mutation ApplyBaseAuxVariation($name: String!, $model: String!, $value: Float!, $flow: Int!) {
        applyBaseAuxVariation(name: $name, model: $model, value: $value, flow: $flow)
    }`;

export const GLOBAL_OPTIONAL_VARIATION = gql`
    mutation ApplyGlobalOptionalVariation($name: String!, $delta: Float, $valueType: GlobalTypeEnum, $value: Float) {
        applyGlobalOptionalVariation(name: $name, delta: $delta, value_type: $valueType, value: $value)
    }`;

export const OPTIONAL_VARIATION = gql`
    mutation ApplyOptionalVariation($name: String!, $model: String!, $delta: Float, $flow: Int, $value: Float) {
        applyOptionalVariation(name: $name, model: $model, delta: $delta, flow: $flow, value: $value)
    }`;

export const BASE_MACHINE_VARIATION = gql`
    mutation ApplyBaseMachineVariation($name: String!, $value: Float!, $machineModel: String!) {
        applyBaseMachineVariation(name: $name, value: $value, machineModel: $machineModel)
    }`;

export const GENERAL_VARIATION = gql`
    mutation ApplyGeneralCostVariation($name: String!, $costType: String!, $variationType: VariationTypeEnum!, $amount: Float!) {
        applyGeneralCostVariation(name: $name, cost_type: $costType, variation_type: $variationType, amount: $amount)
    }`;

export const IDRO_DELTA_VARIATION = gql`
    mutation SetDelta($model: String!, $value: Float!) {
      setDelta(model: $model, value: $value)
    }
`;

export const OPTIONAL_DELTA_VARIATION = gql`
    mutation SetOptionalDelta($name: String!, $value: Float!) {
      setOptionalDelta(name: $name, value: $value)
    }
`;

export const COST_CENTERS_QUERY = gql`
    query CostCenters {
        costCenters {
            name
            type
        }
    }
`;

export const RAW_OPTIONALS = gql`
    query RawOptionals($model: String!, $flow: Int!) {
        rawOptionals(model: $model, flow: $flow) {
            name
            type
            model
            flow
            cost
            delta
        }
    }
`;

export const PDF_QUERY = gql`
    query Query($conf: [CostSpecInput]!, $model: String!, $flow: Int!, $optionals: [OptionalSpecInput]!, $offerParams: [KeyValue]!, $inEx: [KeyValue]!, $utilities: [KeyValue]!, $discount: String, $extraInfo: [KeyValue], $extraCosts: [KeyValue]) {
        pdf(conf: $conf, model: $model, flow: $flow, optionals: $optionals, offerParams: $offerParams, inEx: $inEx, utilities: $utilities, discount: $discount, extraInfo: $extraInfo, extraCosts: $extraCosts)
    }
`;

